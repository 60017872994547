"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.deleteSectionAdminPayload = exports.deleteProgramAdminPayload = exports.editSectionPayload = exports.createSectionPayload = exports.getProgramDetailPayload = exports.editProgramPayload = exports.createProgramPayload = exports.deleteTherapyNotePayload = exports.getUserSessionPayload = exports.updateTherapyNotePayload = exports.createTherapyNotePayload = exports.getTherapyNotePayload = exports.updatePatientProgressContentPayload = exports.updatePatientProgressSectionPayload = exports.getPatientProgressPayload = exports.getPatientProcessQuestion = exports.listPatientProgressPayload = exports.listSectionsPayload = exports.deleteUserPayload = exports.updateUserPayload = exports.getUserPayload = exports.createUserPayload = exports.listCaregiversPayload = exports.listProgramsPayload = exports.bulkUsersPayload = exports.listUsersPayload = exports.createPaymentPayload = exports.sendSupportMessagePayload = exports.answerQuestionsPayload = exports.setVideoStatePayload = exports.getSectionsPayload = exports.getProgramPayload = exports.logoutPayload = exports.hydrateUserPayload = exports.signUpPayload = exports.resetPasswordChangePayload = exports.resetPasswordPayload = exports.verifyEmailPostPayload = exports.verifyEmailPayload = exports.passwordCreationPayload = exports.loginPayload = void 0;
const services_1 = require("@/helpers/services");
const _1 = require(".");
const loginPayload = (data) => ({
    serviceKey: services_1.endpoints.login,
    successActionCreator: _1.ActionCreators.user.loginSuccess.dispatch,
    errorActionCreator: _1.ActionCreators.user.loginFailure.dispatch,
    data,
});
exports.loginPayload = loginPayload;
const passwordCreationPayload = (data) => ({
    serviceKey: services_1.endpoints.createPassword,
    data,
    successActionCreator: _1.ActionCreators.user.createPasswordSuccess.dispatch,
});
exports.passwordCreationPayload = passwordCreationPayload;
const verifyEmailPayload = (data) => ({
    serviceKey: services_1.endpoints.verifyEmailGet,
    data,
});
exports.verifyEmailPayload = verifyEmailPayload;
const verifyEmailPostPayload = (data) => ({
    serviceKey: services_1.endpoints.verifyEmailPost,
    data,
});
exports.verifyEmailPostPayload = verifyEmailPostPayload;
const resetPasswordPayload = (data) => ({
    serviceKey: services_1.endpoints.getResetPassword,
    successActionCreator: _1.ActionCreators.user.resetPasswordSuccess.dispatch,
    data,
});
exports.resetPasswordPayload = resetPasswordPayload;
const resetPasswordChangePayload = (data) => ({
    serviceKey: services_1.endpoints.postResetPassword,
    successActionCreator: _1.ActionCreators.user.resetPasswordChangeSuccess.dispatch,
    data,
});
exports.resetPasswordChangePayload = resetPasswordChangePayload;
const signUpPayload = (data) => ({
    serviceKey: services_1.endpoints.signup,
    successActionCreator: _1.ActionCreators.user.signUpSuccess.dispatch,
    errorActionCreator: _1.ActionCreators.user.signUpFailure.dispatch,
    data,
});
exports.signUpPayload = signUpPayload;
const hydrateUserPayload = () => ({
    serviceKey: services_1.endpoints.me,
    data: null,
    successActionCreator: _1.ActionCreators.user.hydrateUserSuccess.dispatch,
    errorActionCreator: _1.ActionCreators.user.hydrateUserFailure.dispatch,
});
exports.hydrateUserPayload = hydrateUserPayload;
const logoutPayload = () => ({
    serviceKey: services_1.endpoints.logoutUser,
    data: null
});
exports.logoutPayload = logoutPayload;
const getProgramPayload = (id) => ({
    serviceKey: services_1.endpoints.getProgram,
    data: id,
    successActionCreator: _1.ActionCreators.program.getProgramSuccess.dispatch,
});
exports.getProgramPayload = getProgramPayload;
const getSectionsPayload = (data) => ({
    serviceKey: services_1.endpoints.getSections,
    data,
    successActionCreator: _1.ActionCreators.program.getSectionsSuccess.dispatch,
    meta: data,
});
exports.getSectionsPayload = getSectionsPayload;
const setVideoStatePayload = (data) => ({
    serviceKey: services_1.endpoints.setVideoState,
    data,
    successActionCreator: _1.ActionCreators.program.setVideoStateSuccess.dispatch,
    meta: data,
});
exports.setVideoStatePayload = setVideoStatePayload;
const answerQuestionsPayload = (programId, sectionId, processQuestionId, answers, questions) => {
    const data = answers.map((answer, idx) => ({ question_id: questions[idx].id, answer }));
    return {
        serviceKey: services_1.endpoints.answerProcessQuestions,
        data: { pk: processQuestionId, data },
        successActionCreator: _1.ActionCreators.program.setProcessQuestionSuccess.dispatch,
        meta: { programId, sectionId },
    };
};
exports.answerQuestionsPayload = answerQuestionsPayload;
const sendSupportMessagePayload = (data) => ({
    serviceKey: services_1.endpoints.sendSupportMessage,
    data,
    successActionCreator: _1.ActionCreators.user.sendSupportMessageSuccess.dispatch,
    meta: data,
});
exports.sendSupportMessagePayload = sendSupportMessagePayload;
const createPaymentPayload = (data) => ({
    serviceKey: services_1.endpoints.createPayment,
    data,
    successActionCreator: _1.ActionCreators.payment.createPaymentSuccess.dispatch,
    errorActionCreator: _1.ActionCreators.payment.createPaymentFailure.dispatch,
    meta: data,
});
exports.createPaymentPayload = createPaymentPayload;
const listUsersPayload = (payload) => {
    const data = __rest(payload, []);
    return {
        data: Object.assign({}, data),
        serviceKey: services_1.endpoints.listUsers,
        successActionCreator: _1.ActionCreators.user.listUsersSuccess.dispatch,
        errorActionCreator: _1.ActionCreators.user.listUserFailure.dispatch,
    };
};
exports.listUsersPayload = listUsersPayload;
const bulkUsersPayload = (data) => ({
    serviceKey: services_1.endpoints.bulkUsers,
    data,
    successActionCreator: _1.ActionCreators.user.bulkUsersSuccess.dispatch,
    errorActionCreator: _1.ActionCreators.user.bulkUsersFailure.dispatch,
});
exports.bulkUsersPayload = bulkUsersPayload;
const listProgramsPayload = (data) => ({
    serviceKey: services_1.endpoints.listPrograms,
    data,
    successActionCreator: _1.ActionCreators.program.listProgramsSuccess.dispatch,
    errorActionCreator: _1.ActionCreators.program.listProgramsFailure.dispatch
});
exports.listProgramsPayload = listProgramsPayload;
const listCaregiversPayload = () => ({
    serviceKey: services_1.endpoints.listCaregivers,
    data: null,
    successActionCreator: _1.ActionCreators.user.listCaregiversSuccess.dispatch,
    errorActionCreator: _1.ActionCreators.user.listCaregiversFailure.dispatch
});
exports.listCaregiversPayload = listCaregiversPayload;
const createUserPayload = (data) => ({
    serviceKey: services_1.endpoints.createUser,
    data,
    successActionCreator: _1.ActionCreators.user.createUserSuccess.dispatch,
    errorActionCreator: _1.ActionCreators.user.createUserFailure.dispatch,
    meta: data
});
exports.createUserPayload = createUserPayload;
const getUserPayload = (id) => ({
    serviceKey: services_1.endpoints.getUser,
    data: id,
    successActionCreator: _1.ActionCreators.user.getUserSuccess.dispatch,
});
exports.getUserPayload = getUserPayload;
const updateUserPayload = (data) => ({
    serviceKey: services_1.endpoints.updateUser,
    data,
    successActionCreator: _1.ActionCreators.user.updateUserSuccess.dispatch,
    errorActionCreator: _1.ActionCreators.user.updateUserFailure.dispatch,
    meta: data
});
exports.updateUserPayload = updateUserPayload;
const deleteUserPayload = (data) => ({
    serviceKey: services_1.endpoints.deleteUser,
    data,
    successActionCreator: _1.ActionCreators.user.deleteUserSuccess.dispatch,
    errorActionCreator: _1.ActionCreators.user.deleteUserFailure.dispatch,
    meta: data
});
exports.deleteUserPayload = deleteUserPayload;
const listSectionsPayload = (data) => ({
    serviceKey: services_1.endpoints.listSectionsSimple,
    data,
    successActionCreator: _1.ActionCreators.program.listSectionsSimpleSuccess.dispatch,
    errorActionCreator: _1.ActionCreators.program.listSectionsSimpleFailure.dispatch
});
exports.listSectionsPayload = listSectionsPayload;
const listPatientProgressPayload = (data) => ({
    serviceKey: services_1.endpoints.listPatientProgress,
    data,
    successActionCreator: _1.ActionCreators.user.listPatientProgressSuccess.dispatch,
    errorActionCreator: _1.ActionCreators.user.listPatientProgressFailure.dispatch
});
exports.listPatientProgressPayload = listPatientProgressPayload;
const getPatientProcessQuestion = (data) => ({
    serviceKey: services_1.endpoints.getPatientProcessQuestion,
    data,
    successActionCreator: _1.ActionCreators.user.getPatientProcessQuestionSuccess.dispatch,
    errorActionCreator: _1.ActionCreators.user.getPatientProcessQuestionFailure.dispatch
});
exports.getPatientProcessQuestion = getPatientProcessQuestion;
const getPatientProgressPayload = (data) => ({
    serviceKey: services_1.endpoints.getPatientProgress,
    data,
    successActionCreator: _1.ActionCreators.user.getPatientProgressSuccess.dispatch,
    errorActionCreator: _1.ActionCreators.user.getPatientProgressFailure.dispatch
});
exports.getPatientProgressPayload = getPatientProgressPayload;
const updatePatientProgressSectionPayload = (data) => ({
    serviceKey: services_1.endpoints.updatePatientProgressSection,
    data,
    successActionCreator: _1.ActionCreators.user.updatePatientProgressSectionSuccess.dispatch,
    errorActionCreator: _1.ActionCreators.user.updatePatientProgressSectionFailure.dispatch
});
exports.updatePatientProgressSectionPayload = updatePatientProgressSectionPayload;
const updatePatientProgressContentPayload = (data) => ({
    serviceKey: services_1.endpoints.updatePatientProgressContent,
    data,
    successActionCreator: _1.ActionCreators.user.updatePatientProgressContentSuccess.dispatch,
    errorActionCreator: _1.ActionCreators.user.updatePatientProgressContentFailure.dispatch
});
exports.updatePatientProgressContentPayload = updatePatientProgressContentPayload;
const getTherapyNotePayload = (data) => ({
    serviceKey: services_1.endpoints.getTherapyNote,
    data,
    successActionCreator: _1.ActionCreators.therapy.getTherapyNoteSuccess.dispatch,
    errorActionCreator: _1.ActionCreators.therapy.getTherapyNoteFailure.dispatch
});
exports.getTherapyNotePayload = getTherapyNotePayload;
const createTherapyNotePayload = (data) => ({
    serviceKey: services_1.endpoints.createTherapyNote,
    data,
    successActionCreator: _1.ActionCreators.therapy.createTherapyNoteSuccess.dispatch,
    errorActionCreator: _1.ActionCreators.therapy.createTherapyNoteFailure.dispatch,
    meta: data,
});
exports.createTherapyNotePayload = createTherapyNotePayload;
const updateTherapyNotePayload = (data) => ({
    serviceKey: services_1.endpoints.updateTherapyNote,
    data,
    successActionCreator: _1.ActionCreators.therapy.updateTherapyNoteSuccess.dispatch,
    errorActionCreator: _1.ActionCreators.therapy.updateTherapyNoteFailure.dispatch
});
exports.updateTherapyNotePayload = updateTherapyNotePayload;
const getUserSessionPayload = (data) => ({
    serviceKey: services_1.endpoints.getUserSession,
    data,
    successActionCreator: _1.ActionCreators.program.getUserSessionSuccess.dispatch,
    errorActionCreator: _1.ActionCreators.program.getUserSessionFailure.dispatch
});
exports.getUserSessionPayload = getUserSessionPayload;
const deleteTherapyNotePayload = (data) => ({
    serviceKey: services_1.endpoints.deleteTherapyNote,
    data,
    successActionCreator: _1.ActionCreators.therapy.deleteTherapyNoteSuccess.dispatch,
    errorActionCreator: _1.ActionCreators.therapy.deleteTherapyNoteFailure.dispatch,
    meta: data,
});
exports.deleteTherapyNotePayload = deleteTherapyNotePayload;
const createProgramPayload = (data) => ({
    serviceKey: services_1.endpoints.createProgram,
    data,
    successActionCreator: _1.ActionCreators.program.createProgramSuccess.dispatch,
    errorActionCreator: _1.ActionCreators.program.createProgramFailure.dispatch
});
exports.createProgramPayload = createProgramPayload;
const editProgramPayload = (data) => ({
    serviceKey: services_1.endpoints.editProgram,
    data
});
exports.editProgramPayload = editProgramPayload;
const getProgramDetailPayload = (data) => ({
    serviceKey: services_1.endpoints.getProgramDetail,
    data,
    successActionCreator: _1.ActionCreators.program.getProgramDetailSuccess.dispatch,
});
exports.getProgramDetailPayload = getProgramDetailPayload;
const createSectionPayload = (data) => ({
    serviceKey: services_1.endpoints.createSection,
    data,
    successActionCreator: _1.ActionCreators.program.createSectionSuccess.dispatch,
    errorActionCreator: _1.ActionCreators.program.createSectionFailure.dispatch,
    meta: { order: data === null || data === void 0 ? void 0 : data.order }
});
exports.createSectionPayload = createSectionPayload;
const editSectionPayload = (data) => ({
    serviceKey: services_1.endpoints.editSection,
    data,
    successActionCreator: _1.ActionCreators.program.editSectionSuccess.dispatch,
    errorActionCreator: _1.ActionCreators.program.editSectionFailure.dispatch,
    meta: { order: data === null || data === void 0 ? void 0 : data.order }
});
exports.editSectionPayload = editSectionPayload;
const deleteProgramAdminPayload = (data) => ({
    serviceKey: services_1.endpoints.deleteProgramAdmin,
    data,
    successActionCreator: _1.ActionCreators.program.deleteAdminProgramSuccess.dispatch,
    errorActionCreator: _1.ActionCreators.program.deleteAdminProgramFailure.dispatch,
    meta: data,
});
exports.deleteProgramAdminPayload = deleteProgramAdminPayload;
const deleteSectionAdminPayload = (data) => ({
    serviceKey: services_1.endpoints.deleteSection,
    data,
    successActionCreator: _1.ActionCreators.program.deleteAdminSectionSuccess.dispatch,
    errorActionCreator: _1.ActionCreators.program.deleteAdminSectionFailure.dispatch,
    meta: data,
});
exports.deleteSectionAdminPayload = deleteSectionAdminPayload;
