"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@emotion/core");
exports.default = {
    container: (width) => core_1.css `
    width: min(${width}px, 80vw);
  `,
    footer: (justifyContent, gap) => core_1.css `
    justify-content: ${justifyContent};
    gap: ${gap}px;
  `
};
