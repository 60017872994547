"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lodash_1 = require("lodash");
const index_1 = require("../layouts/index");
const getStepLayoutStore = (store) => store.stepLayout;
const getStepLayoutOpen = (store) => getStepLayoutStore(store).open;
const getStepLayoutLayout = (store) => getStepLayoutStore(store).layout;
const getStepLayoutPayload = (store) => getStepLayoutStore(store).payload;
const isTherapySession = (layout) => lodash_1.includes(Object.values(index_1.THERAPY_SESSION_LAYOUTS), layout);
exports.default = {
    getStepLayoutOpen,
    getStepLayoutLayout,
    getStepLayoutPayload,
    isTherapySession
};
