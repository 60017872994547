"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.motionVariants = exports.menuDividerDynamicStyle = exports.menuItemDynamicStyle = exports.menuDynamicStyle = void 0;
const react_1 = require("@emotion/react");
const menuDynamicStyle = (color, backgroundColor, borderRadius, posLeft, posTop, posRight, buttonWidth, offset) => react_1.css `
    color: ${color};
    background-color: ${backgroundColor};
    border-radius: ${borderRadius};
    min-width: ${buttonWidth}px;
    top: ${posTop}px;
    ${offset ? `left: ${posLeft}px; right: ${posRight}px;` : ''}
  `;
exports.menuDynamicStyle = menuDynamicStyle;
const menuItemDynamicStyle = (hoverColor, isFirstChild, isLastChild, borderRadius) => react_1.css `
  position: relative;
  display: flex;
  cursor: pointer;
  padding-left: 20px;
  align-items: center;
  height: 40px;
  border-top-left-radius: ${isFirstChild ? borderRadius : 'none'};
  border-top-right-radius: ${isFirstChild ? borderRadius : 'none'};
  border-bottom-left-radius: ${isLastChild ? borderRadius : 'none'};
  border-bottom-right-radius: ${isLastChild ? borderRadius : 'none'};
  &:hover {
    background-color: ${hoverColor};
  }
`;
exports.menuItemDynamicStyle = menuItemDynamicStyle;
const menuDividerDynamicStyle = (stroke, color) => react_1.css `
  width: 100%;
  background-color: ${color};
  height: ${stroke};
  
`;
exports.menuDividerDynamicStyle = menuDividerDynamicStyle;
exports.motionVariants = {
    enter: {
        visibility: 'visible',
        opacity: 1,
        scale: 1,
        transition: {
            duration: 0.2,
            ease: [0.4, 0, 0.2, 1],
        },
    },
    exit: {
        transitionEnd: {
            visibility: 'hidden',
        },
        opacity: 0,
        scale: 0.8,
        transition: {
            duration: 0.1,
            easings: 'easeOut',
        },
    },
};
