"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LAYOUTS = exports.PROCESS_QUESTIONS_LAYOUTS = exports.THERAPY_SESSION_LAYOUTS = void 0;
const process_questions_1 = require("./process-questions");
const session_1 = require("./session");
exports.THERAPY_SESSION_LAYOUTS = {
    SESSION_STEP_ONE: 'SESSION_STEP_ONE',
    SESSION_STEP_TWO: 'SESSION_STEP_TWO',
};
exports.PROCESS_QUESTIONS_LAYOUTS = {
    PROCESS_QUESTIONS: 'PROCESS_QUESTIONS',
    PROCESS_QUESTIONS_FINISH: 'PROCESS_QUESTIONS_FINISH',
    PROCESS_QUESTIONS_SUMMARY: 'PROCESS_QUESTIONS_SUMMARY',
};
exports.LAYOUTS = Object.assign(Object.assign({}, exports.THERAPY_SESSION_LAYOUTS), exports.PROCESS_QUESTIONS_LAYOUTS);
const Layouts = {
    [exports.LAYOUTS.SESSION_STEP_ONE]: session_1.SessionStepOneLayout,
    [exports.LAYOUTS.SESSION_STEP_TWO]: session_1.SessionStepTwoLayout,
    [exports.LAYOUTS.PROCESS_QUESTIONS]: process_questions_1.ProcessQuestions,
    [exports.LAYOUTS.PROCESS_QUESTIONS_FINISH]: process_questions_1.FinishProcessQuestion,
    [exports.LAYOUTS.PROCESS_QUESTIONS_SUMMARY]: process_questions_1.SummaryProcessQuestion,
};
exports.default = Layouts;
