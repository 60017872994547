"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TimePickerInputField = exports.DatePickerInputField = exports.RadioInputField = exports.DropdownInputField = exports.CheckboxInputField = exports.TextAreaField = exports.PasswordInputField = exports.TextInputField = void 0;
const formik_1 = require("formik");
const lodash_1 = require("lodash");
const react_1 = __importStar(require("react"));
const components_1 = require("@/components");
const styles_1 = __importStar(require("@/components/dropdown/styles"));
const constants_1 = require("@/constants");
const addFormikFieldProps = (name) => {
    const { errors, values, handleBlur, handleChange } = formik_1.useFormikContext();
    return {
        // @ts-ignore
        error: errors[name],
        // @ts-ignore
        value: values[name],
        onBlur: handleBlur,
        onChange: handleChange,
    };
};
const TextInputField = (_a) => {
    var { name, onKeyDownSubmit } = _a, rest = __rest(_a, ["name", "onKeyDownSubmit"]);
    const { handleSubmit } = formik_1.useFormikContext();
    return (react_1.default.createElement(components_1.Input, Object.assign({ name: name }, rest, addFormikFieldProps(name), { onKeyDown: (event) => {
            if (onKeyDownSubmit && event.key === 'Enter') {
                handleSubmit();
            }
        } })));
};
exports.TextInputField = TextInputField;
exports.TextInputField.defaultProps = {
    id: undefined,
    value: undefined,
    onChange: undefined,
    numberOfLines: undefined,
    placeholder: undefined,
    ariaLabel: '',
    type: 'text',
    error: '',
    onBlur: undefined,
    className: null,
    accessory: null,
    label: '',
    onKeyDownSubmit: false,
    staticLabel: false,
    required: false,
    helper: null
};
const PasswordInputField = (_a) => {
    var { name, onKeyDownSubmit } = _a, rest = __rest(_a, ["name", "onKeyDownSubmit"]);
    const [passwordVisible, setPasswordVisible] = react_1.useState(false);
    const { handleSubmit } = formik_1.useFormikContext();
    return (react_1.default.createElement(components_1.Input, Object.assign({}, rest, { type: passwordVisible ? 'text' : 'password', name: name, viewPasswordImage: {
            open: { default: constants_1.Images.eye, active: constants_1.Images.eyeActive, error: constants_1.Images.eyeError },
            close: {
                default: constants_1.Images.eyeClose,
                active: constants_1.Images.eyeCloseActive,
                error: constants_1.Images.eyeCloseError,
            },
        }, onKeyDown: (event) => {
            if (onKeyDownSubmit && event.key === 'Enter') {
                handleSubmit();
            }
        }, onToggleViewPassword: (visible) => setPasswordVisible(visible) }, addFormikFieldProps(name))));
};
exports.PasswordInputField = PasswordInputField;
exports.PasswordInputField.defaultProps = {
    id: undefined,
    value: undefined,
    placeholder: undefined,
    numberOfLines: undefined,
    onChange: undefined,
    ariaLabel: '',
    type: 'password',
    error: '',
    onBlur: undefined,
    className: null,
    accessory: null,
    label: '',
    onKeyDownSubmit: false,
    staticLabel: false,
    required: false,
    helper: null
};
const TextAreaField = ({ name, onChange, ariaLabel, placeholder, className, label, numberOfLines, }) => (react_1.default.createElement(components_1.TextArea, Object.assign({ className: className, name: name, ariaLabel: ariaLabel, label: label, placeholder: placeholder, numberOfLines: numberOfLines, onChange: onChange }, addFormikFieldProps(name))));
exports.TextAreaField = TextAreaField;
exports.TextAreaField.defaultProps = {
    id: undefined,
    value: undefined,
    placeholder: undefined,
    numberOfLines: undefined,
    onChange: undefined,
    ariaLabel: '',
    type: 'text',
    error: '',
    onBlur: undefined,
    className: null,
    accessory: null,
    label: '',
    onKeyDownSubmit: false,
    staticLabel: false,
    required: false,
    helper: null
};
const CheckboxInputField = (_a) => {
    var { name, ariaLabel } = _a, rest = __rest(_a, ["name", "ariaLabel"]);
    return (react_1.default.createElement(components_1.Checkbox, Object.assign({ id: name, name: name, ariaLabel: ariaLabel }, rest, addFormikFieldProps(name))));
};
exports.CheckboxInputField = CheckboxInputField;
exports.CheckboxInputField.defaultProps = {
    id: undefined,
    name: '',
    type: undefined,
    label: undefined,
    value: undefined,
    error: undefined,
    placeholder: undefined,
    ariaLabel: undefined,
    accessory: undefined,
    onBlur: undefined,
    onChange: undefined,
    className: '',
    numberOfLines: undefined,
    onKeyDownSubmit: false,
    staticLabel: false,
    required: false,
    helper: null
};
const DropdownInputField = (_a) => {
    var _b;
    var { name, options, onChangeRaw } = _a, rest = __rest(_a, ["name", "options", "onChangeRaw"]);
    const { values, setFieldValue } = formik_1.useFormikContext();
    const onOptionChange = (value, action) => {
        if (onChangeRaw) {
            onChangeRaw(value);
        }
        // @ts-ignore
        return action.action === 'select-option' ? setFieldValue(name, value === null || value === void 0 ? void 0 : value.value) : {};
    };
    return (react_1.default.createElement(components_1.Dropdown, Object.assign({ name: name, options: options }, rest, addFormikFieldProps(name), { 
        // @ts-ignore
        value: lodash_1.find(options, { value: values[name] }) || ((_b = options[0]) === null || _b === void 0 ? void 0 : _b.value), onChange: onOptionChange })));
};
exports.DropdownInputField = DropdownInputField;
exports.DropdownInputField.defaultProps = {
    error: undefined,
    label: '',
    testId: '',
    ariaLabel: 'Dropdown',
    className: '',
    disabled: false,
    multiselect: false,
    onBlur: null,
    onChange: undefined,
    placeholder: '',
    selectStyles: styles_1.default,
    selectTheme: styles_1.CustomSelectTheme,
    value: undefined,
    defaultValue: undefined,
    onChangeRaw: undefined,
    required: false,
    helper: null
};
const RadioInputField = (_a) => {
    var { name, value } = _a, rest = __rest(_a, ["name", "value"]);
    const { values, setFieldValue } = formik_1.useFormikContext();
    return (react_1.default.createElement(components_1.RadioInput, Object.assign({ name: name }, rest, addFormikFieldProps(name), { value: value, 
        // @ts-ignore
        checked: values[name] === value, onChange: (event) => {
            setFieldValue(name, event.currentTarget.value);
        } })));
};
exports.RadioInputField = RadioInputField;
exports.RadioInputField.defaultProps = {
    className: '',
};
const DatePickerInputField = (_a) => {
    var { name } = _a, rest = __rest(_a, ["name"]);
    const { values, setFieldValue } = formik_1.useFormikContext();
    const onDateChange = (date) => {
        setFieldValue(name, new Date(date));
    };
    return (react_1.default.createElement(components_1.DatePicker, Object.assign({ name: name }, rest, addFormikFieldProps(name), { 
        // @ts-ignore
        selected: values[name], onChange: onDateChange })));
};
exports.DatePickerInputField = DatePickerInputField;
exports.DatePickerInputField.defaultProps = {
    className: '',
    onChange: undefined,
    type: 'date',
    error: ''
};
const TimePickerInputField = (_a) => {
    var { name } = _a, rest = __rest(_a, ["name"]);
    return react_1.default.createElement(components_1.TimePicker, Object.assign({ name: name }, rest, addFormikFieldProps(name)));
};
exports.TimePickerInputField = TimePickerInputField;
