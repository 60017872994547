"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.reducer = exports.reduxSet = exports.INITIAL_STATE = exports.STEPS = exports.ACTIONS = void 0;
const radio_dispatch_1 = require("@clearsummit/radio-dispatch");
const index_1 = require("../layouts/index");
exports.ACTIONS = {
    SESSION_STEP_ONE: 'STEP_LAYOUT/SESSION_STEP_ONE',
    SESSION_STEP_TWO: 'STEP_LAYOUT/SESSION_STEP_TWO',
    CLOSE: 'STEP_LAYOUT/CLOSE',
    PROCESS_QUESTIONS: 'STEP_LAYOUT/PROCESS_QUESTIONS',
    PROCESS_QUESTIONS_FINISH: 'STEP_LAYOUT/PROCESS_QUESTIONS_FINISH',
    PROCESS_QUESTIONS_SUMMARY: 'STEP_LAYOUT/PROCESS_QUESTIONS_SUMMARY'
};
exports.STEPS = {
    SESSION_STEP_ONE: 'SESSION_STEP_ONE',
    SESSION_STEP_TWO: 'SESSION_STEP_TWO'
};
exports.INITIAL_STATE = {
    layout: null,
    open: false,
    payload: null
};
const sessionStepOne = (state, payload) => (Object.assign(Object.assign({}, state), { open: true, layout: index_1.LAYOUTS.SESSION_STEP_ONE, payload }));
const sessionStepTwo = (state) => (Object.assign(Object.assign({}, state), { open: true, layout: index_1.LAYOUTS.SESSION_STEP_TWO }));
const closeStepLayout = () => (Object.assign({}, exports.INITIAL_STATE));
const processQuestions = (state, payload) => (Object.assign(Object.assign({}, state), { open: true, layout: index_1.LAYOUTS.PROCESS_QUESTIONS, payload }));
const processQuestionsFinish = (state) => (Object.assign(Object.assign({}, state), { open: true, layout: index_1.LAYOUTS.PROCESS_QUESTIONS_FINISH }));
const processQuestionsSummary = (state, payload) => (Object.assign(Object.assign({}, state), { open: true, layout: index_1.LAYOUTS.PROCESS_QUESTIONS_SUMMARY, payload }));
exports.reduxSet = {
    sessionStepOne: radio_dispatch_1.dispatchReducer(exports.ACTIONS.SESSION_STEP_ONE, sessionStepOne),
    sessionStepTwo: radio_dispatch_1.dispatchReducer(exports.ACTIONS.SESSION_STEP_TWO, sessionStepTwo),
    closeStepLayout: radio_dispatch_1.dispatchReducer(exports.ACTIONS.CLOSE, closeStepLayout),
    processQuestions: radio_dispatch_1.dispatchReducer(exports.ACTIONS.PROCESS_QUESTIONS, processQuestions),
    processQuestionsFinish: radio_dispatch_1.dispatchReducer(exports.ACTIONS.PROCESS_QUESTIONS_FINISH, processQuestionsFinish),
    processQuestionsSummary: radio_dispatch_1.dispatchReducer(exports.ACTIONS.PROCESS_QUESTIONS_SUMMARY, processQuestionsSummary),
};
const reducer = (state = Object.assign({}, exports.INITIAL_STATE), action) => radio_dispatch_1.runReducers(state, action, exports.reduxSet);
exports.reducer = reducer;
