"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.reducer = exports.reduxSet = exports.INITIAL_STATE = exports.ACTIONS = void 0;
const radio_dispatch_1 = require("@clearsummit/radio-dispatch");
const shared_reducers_1 = require("@/redux/shared-reducers");
exports.ACTIONS = {
    SELECT_SESSION_COUNT: 'PAYMENT/SELECT_SESSION_COUNT',
    CREATE_PAYMENT_SUCCESS: 'PAYMENT/CREATE_PAYMENT_SUCCESS',
    CREATE_PAYMENT_FAILURE: 'PAYMENT/CREATE_PAYMENT_FAILURE',
};
exports.INITIAL_STATE = {
    sessionCount: 'all',
    client_secret: null,
};
const selectSessionCount = (state, payload) => (Object.assign(Object.assign({}, state), { sessionCount: payload }));
const createPaymentSuccess = (state, payload) => {
    if (payload === null || payload === void 0 ? void 0 : payload.data) {
        const { data: { data: { client_secret }, }, } = payload;
        return Object.assign(Object.assign({}, state), { client_secret });
    }
    return Object.assign({}, state);
};
exports.reduxSet = {
    selectSessionCount: radio_dispatch_1.dispatchReducer(exports.ACTIONS.SELECT_SESSION_COUNT, selectSessionCount),
    createPaymentSuccess: radio_dispatch_1.dispatchReducer(exports.ACTIONS.CREATE_PAYMENT_SUCCESS, createPaymentSuccess),
    createPaymentFailure: radio_dispatch_1.dispatchReducer(exports.ACTIONS.CREATE_PAYMENT_FAILURE, shared_reducers_1.noOp),
};
const reducer = (state = Object.assign({}, exports.INITIAL_STATE), action) => radio_dispatch_1.runReducers(state, action, exports.reduxSet);
exports.reducer = reducer;
