"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.shouldShowPaywallBanner = exports.getNextIncompleteStep = exports.getNextSection = exports.getSectionSession = exports.getCurrentIncompleteSection = void 0;
const summithill_models_1 = require("@clearsummit/summithill-models");
const lodash_1 = require("lodash");
const getCurrentIncompleteSection = (sections) => lodash_1.first(lodash_1.filter(lodash_1.sortBy(sections, (s) => s.order), s => !s.completed));
exports.getCurrentIncompleteSection = getCurrentIncompleteSection;
const getSectionSession = (sectionId, sections) => {
    const section = lodash_1.find(sections, { id: sectionId });
    return lodash_1.first(sections.filter((s) => (s === null || s === void 0 ? void 0 : s.order) > section.order && s instanceof summithill_models_1.SessionSection && section instanceof summithill_models_1.Section));
};
exports.getSectionSession = getSectionSession;
const getNextSection = (sections, currentSection) => currentSection && lodash_1.find(sections, { order: currentSection.order + 1 });
exports.getNextSection = getNextSection;
const getNextIncompleteStep = (currentIncompleteSection, videoId) => {
    const nonCompletedStepsOrdered = lodash_1.sortBy(lodash_1.filter(lodash_1.get(currentIncompleteSection, 'data', []), (step) => !step.completed && step.id !== videoId), (step) => step.order);
    let nextIncompleteStep = lodash_1.first(nonCompletedStepsOrdered);
    if (lodash_1.isEmpty(nonCompletedStepsOrdered)) {
        nextIncompleteStep = currentIncompleteSection === null || currentIncompleteSection === void 0 ? void 0 : currentIncompleteSection.processQuestion;
    }
    return nextIncompleteStep;
};
exports.getNextIncompleteStep = getNextIncompleteStep;
const shouldShowPaywallBanner = (sectionId, sections) => {
    const sectionSession = exports.getSectionSession(sectionId, sections);
    return sectionSession && sectionSession.payment_status === summithill_models_1.SessionPaymentStatus.PENDING;
};
exports.shouldShowPaywallBanner = shouldShowPaywallBanner;
