"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const summithill_models_1 = require("@clearsummit/summithill-models");
const getProgramStore = (store) => store.program;
const getProgram = (store, programId) => {
    const { programs } = getProgramStore(store);
    return programs[programId];
};
const listPrograms = (store) => {
    const { programs } = getProgramStore(store);
    return Object.values(programs);
};
const getUserSession = (store) => {
    const { userSession } = getProgramStore(store);
    return userSession;
};
const getAdminPrograms = (store) => {
    const { adminPrograms } = getProgramStore(store);
    return adminPrograms;
};
const getPendingAdminPrograms = (store) => getProgramStore(store).pendingAdminPrograms;
const getSimpleSections = (store) => {
    const { simpleSections } = getProgramStore(store);
    return simpleSections;
};
const getSections = (store, programId) => {
    const { sections } = getProgramStore(store);
    if (sections[programId]) {
        const { data } = sections[programId];
        return Object.values(data).sort((a, b) => a.order - b.order);
    }
    return [];
};
const getSection = (store, programId, sectionId) => {
    const { sections } = getProgramStore(store);
    if (sections[programId]) {
        const { data } = sections[programId];
        return data[sectionId];
    }
    return new summithill_models_1.Section();
};
const getSectionsNextPage = (store, programId) => {
    const { sections } = getProgramStore(store);
    if (sections[programId]) {
        const { meta } = sections[programId];
        return meta.next;
    }
    return null;
};
const isAvailable = (store, programId, sectionId, contentId) => {
    var _a;
    const section = getSection(store, programId, sectionId);
    const prevSection = getSection(store, programId, section.previous);
    let incompletePrevSection = false;
    let aux = prevSection;
    while (aux !== undefined && aux.id !== '') {
        if (!aux.completed) {
            incompletePrevSection = true;
            break;
        }
        aux = getSection(store, programId, aux.previous);
    }
    let previousEntityComplete = false;
    const currentContent = section.contents.find((item) => item.id.toString() === contentId.toString());
    if (currentContent) {
        let incompleteContent = false;
        if (currentContent.schema === 'videos') {
            const contentOrder = section.entities.videos[currentContent.id].order;
            Object.values(section.entities.videos).forEach((value) => {
                if (value.order < contentOrder && !value.completed) {
                    incompleteContent = true;
                }
            });
        }
        const previousContentId = section.entities[currentContent.schema][contentId].previous;
        const previousContent = section.contents.find((item) => item.id.toString() === contentId.toString());
        if (previousContent) {
            if (incompleteContent || incompletePrevSection) {
                previousEntityComplete = false;
            }
            else {
                previousEntityComplete = section.entities[previousContent.schema][contentId].previous ? (_a = section.entities[previousContent.schema][previousContentId]) === null || _a === void 0 ? void 0 : _a.completed : true;
            }
        }
    }
    const previousSectionComplete = prevSection ? prevSection.completed : true;
    return previousSectionComplete && previousEntityComplete;
};
const getProgramDetail = (store) => getProgramStore(store).adminProgram;
const getAdminSection = (store) => getProgramStore(store).adminSection;
exports.default = {
    getProgram,
    listPrograms,
    getSections,
    getSectionsNextPage,
    isAvailable,
    getAdminPrograms,
    getPendingAdminPrograms,
    getSimpleSections,
    getUserSession,
    getProgramDetail,
    getAdminSection
};
