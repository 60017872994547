"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TherapyNoteFieldNames = exports.VideoCreateFieldNames = exports.QuestionCreateFieldNames = exports.ContentCreateFieldNames = exports.SectionEditFieldNames = exports.SectionCreateFieldNames = exports.ProgramEditFieldNames = exports.ProgramCreateFieldNames = exports.AccountEditFieldNames = exports.AccountCreateFieldNames = exports.SupportFieldNames = exports.CreateQuestionsFieldNames = exports.PasswordCreationFieldNames = exports.SignUpFieldNames = exports.ResetPasswordChangeFieldNames = exports.ResetPasswordFieldNames = exports.LoginFieldNames = void 0;
var LoginFieldNames;
(function (LoginFieldNames) {
    LoginFieldNames["username"] = "username";
    LoginFieldNames["password"] = "password";
})(LoginFieldNames = exports.LoginFieldNames || (exports.LoginFieldNames = {}));
var ResetPasswordFieldNames;
(function (ResetPasswordFieldNames) {
    ResetPasswordFieldNames["username"] = "username";
})(ResetPasswordFieldNames = exports.ResetPasswordFieldNames || (exports.ResetPasswordFieldNames = {}));
var ResetPasswordChangeFieldNames;
(function (ResetPasswordChangeFieldNames) {
    ResetPasswordChangeFieldNames["password"] = "password";
    ResetPasswordChangeFieldNames["confirmPassword"] = "confirmPassword";
})(ResetPasswordChangeFieldNames = exports.ResetPasswordChangeFieldNames || (exports.ResetPasswordChangeFieldNames = {}));
var SignUpFieldNames;
(function (SignUpFieldNames) {
    SignUpFieldNames["email"] = "email";
    SignUpFieldNames["password"] = "password";
    SignUpFieldNames["firstName"] = "first_name";
    SignUpFieldNames["tos"] = "accepted_tos";
})(SignUpFieldNames = exports.SignUpFieldNames || (exports.SignUpFieldNames = {}));
var PasswordCreationFieldNames;
(function (PasswordCreationFieldNames) {
    PasswordCreationFieldNames["password"] = "password";
})(PasswordCreationFieldNames = exports.PasswordCreationFieldNames || (exports.PasswordCreationFieldNames = {}));
var CreateQuestionsFieldNames;
(function (CreateQuestionsFieldNames) {
    CreateQuestionsFieldNames["answer"] = "answer";
})(CreateQuestionsFieldNames = exports.CreateQuestionsFieldNames || (exports.CreateQuestionsFieldNames = {}));
var SupportFieldNames;
(function (SupportFieldNames) {
    SupportFieldNames["message"] = "message";
})(SupportFieldNames = exports.SupportFieldNames || (exports.SupportFieldNames = {}));
var AccountCreateFieldNames;
(function (AccountCreateFieldNames) {
    AccountCreateFieldNames["email"] = "email";
    AccountCreateFieldNames["firstName"] = "firstName";
    AccountCreateFieldNames["lastName"] = "lastName";
    AccountCreateFieldNames["phone"] = "phone";
    AccountCreateFieldNames["groupId"] = "groupId";
    AccountCreateFieldNames["isActive"] = "isActive";
    AccountCreateFieldNames["therapist"] = "therapist";
    AccountCreateFieldNames["caseManager"] = "caseManager";
    AccountCreateFieldNames["sessionPrice"] = "sessionPrice";
    AccountCreateFieldNames["programId"] = "programId";
    AccountCreateFieldNames["totalPaidSessions"] = "totalPaidSessions";
    AccountCreateFieldNames["caregiverType"] = "caregiverType";
    AccountCreateFieldNames["calendarUrl"] = "calendarUrl";
    AccountCreateFieldNames["meetingUrl"] = "meetingUrl";
})(AccountCreateFieldNames = exports.AccountCreateFieldNames || (exports.AccountCreateFieldNames = {}));
var AccountEditFieldNames;
(function (AccountEditFieldNames) {
    AccountEditFieldNames["id"] = "id";
    AccountEditFieldNames["email"] = "email";
    AccountEditFieldNames["firstName"] = "firstName";
    AccountEditFieldNames["lastName"] = "lastName";
    AccountEditFieldNames["phone"] = "phone";
    AccountEditFieldNames["groupId"] = "groupId";
    AccountEditFieldNames["isActive"] = "isActive";
    AccountEditFieldNames["therapist"] = "therapist";
    AccountEditFieldNames["caseManager"] = "caseManager";
    AccountEditFieldNames["sessionPrice"] = "sessionPrice";
    AccountEditFieldNames["programId"] = "programId";
    AccountEditFieldNames["totalPaidSessions"] = "totalPaidSessions";
    AccountEditFieldNames["caregiverType"] = "caregiverType";
    AccountEditFieldNames["calendarUrl"] = "calendarUrl";
    AccountEditFieldNames["meetingUrl"] = "meetingUrl";
})(AccountEditFieldNames = exports.AccountEditFieldNames || (exports.AccountEditFieldNames = {}));
var ProgramCreateFieldNames;
(function (ProgramCreateFieldNames) {
    ProgramCreateFieldNames["name"] = "name";
    ProgramCreateFieldNames["sections"] = "sections";
})(ProgramCreateFieldNames = exports.ProgramCreateFieldNames || (exports.ProgramCreateFieldNames = {}));
var ProgramEditFieldNames;
(function (ProgramEditFieldNames) {
    ProgramEditFieldNames["id"] = "id";
    ProgramEditFieldNames["name"] = "name";
    ProgramEditFieldNames["sections"] = "sections";
})(ProgramEditFieldNames = exports.ProgramEditFieldNames || (exports.ProgramEditFieldNames = {}));
var SectionCreateFieldNames;
(function (SectionCreateFieldNames) {
    SectionCreateFieldNames["program"] = "program";
    SectionCreateFieldNames["name"] = "name";
    SectionCreateFieldNames["order"] = "order";
    SectionCreateFieldNames["type"] = "section_type";
    SectionCreateFieldNames["sessionType"] = "session_type";
    SectionCreateFieldNames["contents"] = "contents";
})(SectionCreateFieldNames = exports.SectionCreateFieldNames || (exports.SectionCreateFieldNames = {}));
var SectionEditFieldNames;
(function (SectionEditFieldNames) {
    SectionEditFieldNames["id"] = "id";
    SectionEditFieldNames["program"] = "program";
    SectionEditFieldNames["name"] = "name";
    SectionEditFieldNames["order"] = "order";
    SectionEditFieldNames["type"] = "section_type";
    SectionEditFieldNames["sessionType"] = "session_type";
    SectionEditFieldNames["contents"] = "contents";
})(SectionEditFieldNames = exports.SectionEditFieldNames || (exports.SectionEditFieldNames = {}));
var ContentCreateFieldNames;
(function (ContentCreateFieldNames) {
    ContentCreateFieldNames["content_type"] = "content_type";
    ContentCreateFieldNames["questions"] = "questions";
    ContentCreateFieldNames["order"] = "order";
})(ContentCreateFieldNames = exports.ContentCreateFieldNames || (exports.ContentCreateFieldNames = {}));
var QuestionCreateFieldNames;
(function (QuestionCreateFieldNames) {
    QuestionCreateFieldNames["wording"] = "wording";
    QuestionCreateFieldNames["order"] = "order";
    QuestionCreateFieldNames["placeholder"] = "placeholder";
})(QuestionCreateFieldNames = exports.QuestionCreateFieldNames || (exports.QuestionCreateFieldNames = {}));
var VideoCreateFieldNames;
(function (VideoCreateFieldNames) {
    VideoCreateFieldNames["name"] = "name";
    VideoCreateFieldNames["url"] = "url";
    VideoCreateFieldNames["duration"] = "duration";
})(VideoCreateFieldNames = exports.VideoCreateFieldNames || (exports.VideoCreateFieldNames = {}));
var TherapyNoteFieldNames;
(function (TherapyNoteFieldNames) {
    TherapyNoteFieldNames["datetime"] = "datetime";
    TherapyNoteFieldNames["behavior"] = "behavior";
    TherapyNoteFieldNames["mood"] = "mood";
    TherapyNoteFieldNames["affect"] = "affect";
    TherapyNoteFieldNames["speech"] = "speech";
    TherapyNoteFieldNames["thoughtProcess"] = "thoughtProcess";
    TherapyNoteFieldNames["cognition"] = "cognition";
    TherapyNoteFieldNames["insight"] = "insight";
    TherapyNoteFieldNames["keyStressorEvent"] = "keyStressorEvent";
    TherapyNoteFieldNames["recoveryEnvNotes"] = "recoveryEnvNotes";
    TherapyNoteFieldNames["sleepingEatingNotes"] = "sleepingEatingNotes";
    TherapyNoteFieldNames["intervention"] = "intervention";
    TherapyNoteFieldNames["interventionResponse"] = "interventionResponse";
    TherapyNoteFieldNames["nextSessionPlan"] = "nextSessionPlan";
    TherapyNoteFieldNames["careNecessity"] = "careNecessity";
    TherapyNoteFieldNames["additionalNotes"] = "additionalNotes";
    TherapyNoteFieldNames["userSession"] = "userSession";
})(TherapyNoteFieldNames = exports.TherapyNoteFieldNames || (exports.TherapyNoteFieldNames = {}));
