"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StatusTypes = exports.BulkTypes = exports.Status = exports.Groups = void 0;
var Groups;
(function (Groups) {
    Groups["ALL"] = "";
    Groups["ADMIN"] = "admin";
    Groups["PATIENT"] = "patient";
    Groups["CAREGIVER"] = "caregiver";
})(Groups = exports.Groups || (exports.Groups = {}));
var Status;
(function (Status) {
    Status["ALL"] = "";
    Status["ACTIVE"] = "true";
    Status["INACTIVE"] = "false";
})(Status = exports.Status || (exports.Status = {}));
var BulkTypes;
(function (BulkTypes) {
    BulkTypes["STATUS"] = "status";
    BulkTypes["INVITE"] = "invite";
})(BulkTypes = exports.BulkTypes || (exports.BulkTypes = {}));
var StatusTypes;
(function (StatusTypes) {
    StatusTypes["ACTIVE"] = "active";
    StatusTypes["INACTIVE"] = "inactive";
})(StatusTypes = exports.StatusTypes || (exports.StatusTypes = {}));
