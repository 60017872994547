"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Container = exports.Card = void 0;
/** @jsx jsx */
/* eslint-disable react/require-default-props */
const core_1 = require("@emotion/core");
const lodash_1 = require("lodash");
const react_1 = require("react");
const react_dnd_1 = require("react-dnd");
const Card = ({ id, item, type, moveCard, findCard }) => {
    const dragRef = react_1.useRef(null);
    const previewRef = react_1.useRef(null);
    const originalIndex = findCard(id).index;
    const [{ isDragging }, drag, preview] = react_dnd_1.useDrag({
        type,
        item: () => ({ id, originalIndex }),
        collect: (monitor) => ({
            isDragging: monitor.isDragging()
        }),
        end: (elem, monitor) => {
            const { id: droppedId, originalIndex: index } = elem;
            const didDrop = monitor.didDrop();
            if (!didDrop) {
                moveCard(droppedId, index);
            }
        }
    }, [originalIndex, id, moveCard]);
    const [, drop] = react_dnd_1.useDrop(() => ({
        accept: type,
        canDrop: () => false,
        hover: ({ id: draggedId }) => {
            if (draggedId !== id) {
                const { index: overIndex } = findCard(id);
                moveCard(draggedId, overIndex);
            }
        }
    }), [findCard, moveCard]);
    const opacity = isDragging ? 0 : 1;
    drag(dragRef);
    drop(preview(previewRef));
    return (core_1.jsx("div", { ref: previewRef, style: { opacity } },
        core_1.jsx(item.type, Object.assign({}, item.props, { dragRef: dragRef }))));
};
exports.Card = Card;
const Container = ({ items, render, onChange, onChangeIndex, className = '' }) => {
    const [cards, setCards] = react_1.useState([...items].map((item) => ({ item, id: lodash_1.uniqueId() })));
    const [toIndex, setToIndex] = react_1.useState(null);
    const type = react_1.useMemo(() => lodash_1.uniqueId('draggable-item-'), []);
    const findCard = (id) => {
        const index = lodash_1.findIndex(cards, { id });
        return { card: cards[index], index };
    };
    const moveCard = react_1.useCallback((id, atIndex) => {
        const { card, index } = findCard(id);
        const mutableCards = [...cards];
        mutableCards.splice(index, 1);
        mutableCards.splice(atIndex, 0, card);
        setToIndex(atIndex);
        setCards(mutableCards);
    }, [findCard, cards, setCards]);
    const [, drop] = react_dnd_1.useDrop(() => ({
        accept: type,
        drop: ({ originalIndex }) => {
            if (onChange)
                onChange(cards);
            if (onChangeIndex)
                onChangeIndex(originalIndex, toIndex);
        }
    }), [cards]);
    react_1.useEffect(() => {
        setCards([...items].map((item) => ({ item, id: lodash_1.uniqueId() })));
    }, [items.length]);
    return (core_1.jsx("div", { ref: drop, className: className }, cards.map((card, index) => {
        var _a;
        return (core_1.jsx(exports.Card
        // @ts-ignore
        , { 
            // @ts-ignore
            key: ((_a = card.item) === null || _a === void 0 ? void 0 : _a.uniqueId) || card.id, index: index, id: card.id, item: render(card.item, index), type: type, moveCard: moveCard, findCard: findCard }));
    })));
};
exports.Container = Container;
exports.default = exports.Container;
