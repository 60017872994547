"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const getPaymentStore = (store) => store.payment;
const getSessionCount = (store) => getPaymentStore(store).sessionCount;
const getSessionPrice = (user, sessionCount) => {
    if (sessionCount === 'all') {
        return (1 * user.totalSessionPrice).toFixed(2);
    }
    return (sessionCount * user.sessionPrice).toFixed(2);
};
const createHash = (user, sessionCount, clientSecret) => {
    const entity = { price: null, clientSecret };
    if (sessionCount === 'all') {
        entity.price = user.totalSessionPrice;
    }
    else {
        entity.price = (sessionCount * user.sessionPrice).toFixed(2);
    }
    return btoa(JSON.stringify(entity));
};
const decodeHash = (hash) => {
    try {
        const jsonDecoded = atob(hash);
        return JSON.parse(jsonDecoded);
    }
    catch (e) {
        return { price: null, clientSecret: null };
    }
};
exports.default = {
    getPaymentStore,
    getSessionCount,
    getSessionPrice,
    createHash,
    decodeHash,
};
