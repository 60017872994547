"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.cleanSortValue = void 0;
const humps_1 = require("humps");
const lodash_1 = require("lodash");
const cleanSortValue = (sortValue) => {
    const value = lodash_1.first(sortValue);
    if (lodash_1.isEmpty(value))
        return undefined;
    return `${value.desc ? '-' : ''}${humps_1.decamelize(value.id)}`;
};
exports.cleanSortValue = cleanSortValue;
