// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-components-textarea-___styles__footerFlexContainer___2Q97M{display:flex;flex-direction:row;justify-content:space-between}.src-components-textarea-___styles__counter___3gbJ0,.src-components-textarea-___styles__helperMessage___3ryYx{font-size:12px}\n", "",{"version":3,"sources":["webpack://src/components/textarea/styles.scss"],"names":[],"mappings":"AAA2nC,gEAAqB,YAAY,CAAC,kBAAkB,CAAC,6BAA6B,CAAC,8GAAwB,cAAc","sourcesContent":[":export{backgroundGrey:#fafafa;black:#1e2520;blue:#1c5898;boxShadowGrey:rgba(11,9,44,0.2);btnActive:#48813c;btnDefault:#5ca44c;btnDisabled:#d0d0d0;btnGhostActive:rgba(92,164,76,0.25);btnGhostDefault:rgba(0,0,0,0);btnGhostHover:rgba(92,164,76,0.1);btnHover:#529244;btnSecondaryActive:#eaa43b;btnSecondaryDefault:#eaa43b;btnSecondaryDisabled:secondary;btnSecondaryHover:#eaa43b;btnTertiary:rgba(68,69,69,0.75);darkGrey:#737474;errorColor:#cd1515;faintBlue:rgba(28,88,152,0.05);green:#59bf28;grey:#d0d0d0;lightBlue:#2fb4f9;lightGrey:#f1f1f1;olive:#20cb9a;orange:#fe7a24;peach:#fdc;primary:#5ca44c;primary01:rgba(92,164,76,0.1);primaryHover:#529244;red:#fa1931;secondary:#eaa43b;secondary005:rgba(249,183,39,0.05);secondary01:rgba(234,164,59,0.1);success:#5ca44c;tertiary:rgba(68,69,69,0.75);transparent:rgba(0,0,0,0);warning:#ee7b23;white:#fff}:export{inputHeight:38px;l:24px;lFont:18px;m:20px;mFont:16px;mobileBreakpoint:600px;s:16px;sFont:14px;slimButtonHeight:24px;smallestSpacer:5px;spacer:15px;tablet:1024px;xl:28px;xlFont:20px;xs:12px;xsFont:12px;xxl:32px;xxlFont:24px;xxs:8px;xxsFont:10px;xxxl:40px;xxxlFont:28px;xxxs:4px;xxxxl:64px;xxxxs:2px}.footerFlexContainer{display:flex;flex-direction:row;justify-content:space-between}.counter,.helperMessage{font-size:12px}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"backgroundGrey": "#fafafa",
	"black": "#1e2520",
	"blue": "#1c5898",
	"boxShadowGrey": "rgba(11,9,44,0.2)",
	"btnActive": "#48813c",
	"btnDefault": "#5ca44c",
	"btnDisabled": "#d0d0d0",
	"btnGhostActive": "rgba(92,164,76,0.25)",
	"btnGhostDefault": "rgba(0,0,0,0)",
	"btnGhostHover": "rgba(92,164,76,0.1)",
	"btnHover": "#529244",
	"btnSecondaryActive": "#eaa43b",
	"btnSecondaryDefault": "#eaa43b",
	"btnSecondaryDisabled": "secondary",
	"btnSecondaryHover": "#eaa43b",
	"btnTertiary": "rgba(68,69,69,0.75)",
	"darkGrey": "#737474",
	"errorColor": "#cd1515",
	"faintBlue": "rgba(28,88,152,0.05)",
	"green": "#59bf28",
	"grey": "#d0d0d0",
	"lightBlue": "#2fb4f9",
	"lightGrey": "#f1f1f1",
	"olive": "#20cb9a",
	"orange": "#fe7a24",
	"peach": "#fdc",
	"primary": "#5ca44c",
	"primary01": "rgba(92,164,76,0.1)",
	"primaryHover": "#529244",
	"red": "#fa1931",
	"secondary": "#eaa43b",
	"secondary005": "rgba(249,183,39,0.05)",
	"secondary01": "rgba(234,164,59,0.1)",
	"success": "#5ca44c",
	"tertiary": "rgba(68,69,69,0.75)",
	"transparent": "rgba(0,0,0,0)",
	"warning": "#ee7b23",
	"white": "#fff",
	"inputHeight": "38px",
	"l": "24px",
	"lFont": "18px",
	"m": "20px",
	"mFont": "16px",
	"mobileBreakpoint": "600px",
	"s": "16px",
	"sFont": "14px",
	"slimButtonHeight": "24px",
	"smallestSpacer": "5px",
	"spacer": "15px",
	"tablet": "1024px",
	"xl": "28px",
	"xlFont": "20px",
	"xs": "12px",
	"xsFont": "12px",
	"xxl": "32px",
	"xxlFont": "24px",
	"xxs": "8px",
	"xxsFont": "10px",
	"xxxl": "40px",
	"xxxlFont": "28px",
	"xxxs": "4px",
	"xxxxl": "64px",
	"xxxxs": "2px",
	"footerFlexContainer": "src-components-textarea-___styles__footerFlexContainer___2Q97M",
	"counter": "src-components-textarea-___styles__counter___3gbJ0",
	"helperMessage": "src-components-textarea-___styles__helperMessage___3ryYx"
};
export default ___CSS_LOADER_EXPORT___;
