"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@emotion/core");
const Styles = {
    closeIcon: (imageUrl) => core_1.css `
    background-image: ${`url(${imageUrl})`};
  `,
    layoutCloseBtn: (imageUrl) => core_1.css `
    background-image: ${`url(${imageUrl})`};
  `
};
exports.default = Styles;
