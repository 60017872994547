"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lodash_1 = require("lodash");
const getUserStore = (store) => store.user;
const getUser = (store) => getUserStore(store).user;
const getUsers = (store) => getUserStore(store).users;
const getFilters = (store) => getUserStore(store).filters;
const getPatientProgressFilters = (store) => getUserStore(store).patientProgressFilters;
const getPatientProgressList = (store) => getUserStore(store).patientProgressList;
const getPatientProgress = (store) => getUserStore(store).patientProgress;
const getPatientProgressAccount = (store) => getUserStore(store).patientProgressAccount;
const getPending = (store) => getUserStore(store).pending;
const getSupportMessageSent = (store) => getUserStore(store).supportMessageSent;
const getPendingBulk = (store) => getUserStore(store).pendingBulk;
const getCaregivers = (store) => getUserStore(store).caregivers;
const getPendingCaregivers = (store) => getUserStore(store).pendingCaregivers;
const getUserDetail = (store) => getUserStore(store).userDetail;
const getPatientProgressCount = (store) => getUserStore(store).patientProgressCount;
const isSectionAvailable = (store, sectionId) => {
    const sections = getPatientProgress(store);
    const section = lodash_1.find(sections, { id: sectionId });
    const nextSection = lodash_1.find(sections, { order: (section === null || section === void 0 ? void 0 : section.order) + 1 });
    const prevSection = lodash_1.find(sections, { order: (section === null || section === void 0 ? void 0 : section.order) - 1 });
    return ((((section === null || section === void 0 ? void 0 : section.state) === 'COMPLETE' || (section === null || section === void 0 ? void 0 : section.order) === 0) &&
        (nextSection === null || nextSection === void 0 ? void 0 : nextSection.state) === 'INCOMPLETE') ||
        (((section === null || section === void 0 ? void 0 : section.state) === 'INCOMPLETE' || nextSection === undefined) &&
            (prevSection === null || prevSection === void 0 ? void 0 : prevSection.state) === 'COMPLETE'));
};
const isContentAvailable = (store, contentId) => {
    const sections = getPatientProgress(store);
    const section = lodash_1.find(sections, { contents: [{ id: contentId }] });
    const content = lodash_1.find((section === null || section === void 0 ? void 0 : section.contents) || [], { id: contentId });
    const nextSection = lodash_1.find(sections, { order: (section === null || section === void 0 ? void 0 : section.order) + 1 });
    const nextContent = lodash_1.find((section === null || section === void 0 ? void 0 : section.contents) || [], { order: (content === null || content === void 0 ? void 0 : content.order) + 1 });
    const prevContent = lodash_1.find((section === null || section === void 0 ? void 0 : section.contents) || [], { order: (content === null || content === void 0 ? void 0 : content.order) - 1 });
    return ((((content === null || content === void 0 ? void 0 : content.state) === 'COMPLETE' || (content === null || content === void 0 ? void 0 : content.order) === 0) &&
        (nextContent === null || nextContent === void 0 ? void 0 : nextContent.state) === 'INCOMPLETE') ||
        (((content === null || content === void 0 ? void 0 : content.state) === 'INCOMPLETE' ||
            (nextContent === undefined && (nextSection === null || nextSection === void 0 ? void 0 : nextSection.state) === 'INCOMPLETE')) &&
            (prevContent === null || prevContent === void 0 ? void 0 : prevContent.state) === 'COMPLETE'));
};
const getPatientProcessQuestions = (store) => getUserStore(store).patientProcessQuestions;
exports.default = {
    getUser,
    getUsers,
    getPending,
    getFilters,
    getSupportMessageSent,
    getPendingBulk,
    getCaregivers,
    getPendingCaregivers,
    getUserDetail,
    getPatientProgressFilters,
    getPatientProgressList,
    getPatientProgressCount,
    getPatientProcessQuestions,
    getPatientProgressAccount,
    getPatientProgress,
    isSectionAvailable,
    isContentAvailable,
};
