"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.reducer = exports.reduxSet = exports.INITIAL_STATE = exports.ACTIONS = void 0;
/* eslint-disable max-lines */
const radio_dispatch_1 = require("@clearsummit/radio-dispatch");
const summithill_models_1 = require("@clearsummit/summithill-models");
const humps_1 = require("humps");
const lodash_1 = __importStar(require("lodash"));
const shared_reducers_1 = require("@/redux/shared-reducers");
// User Actions
exports.ACTIONS = {
    START_UP: 'START_UP',
    SET_USER_SESSION: 'SET_USER_SESSION',
    CREATE_USER: 'CREATE_USER',
    FAILURE_CREATE_USER: 'FAILURE_CREATE_USER',
    FAILURE_LOGIN: 'FAILURE_LOGIN',
    CLEAR_USER_SESSION: 'CLEAR_USER_SESSION',
    HYDRATE_USER_SUCCESS: 'USER/HYDRATE_USER_SUCCESS',
    HYDRATE_USER_FAILURE: 'USER/HYDRATE_USER_FAILURE',
    STORE_USER: 'STORE_USER',
    LOGOUT: 'LOGOUT',
    HYDRATE_USER: 'HYDRATE_USER',
    START_REQUEST: 'USER/START_REQUEST',
    END_REQUEST: 'USER/END_REQUEST',
    RESET_PASSWORD_SUCCESS: 'USER/RESET_PASSWORD_SUCCESS',
    RESET_PASSWORD_CHANGE_SUCCESS: 'USER/RESET_PASSWORD_CHANGE_SUCCESS',
    CREATE_PASSWORD_SUCCESS: 'USER/CREATE_PASSWORD_SUCCESS',
    SEND_SUPPORT_MESSAGE_SUCCESS: 'USER/SEND_SUPPORT_MESSAGE_SUCCESS',
    RESET_SUPPORT_MESSAGE: 'USER/RESET_SUPPORT_MESSAGE',
    LIST_USERS_REQUEST: 'USER/LIST_USERS_REQUEST',
    LIST_USERS_SUCCESS: 'USER/LIST_USERS_SUCCESS',
    LIST_USERS_FAILURE: 'USER/LIST_USERS_FAILURE',
    RESET_LIST_USERS_FILTER: 'USER/RESET_LIST_USERS_FILTER',
    BULK_USERS_REQUEST: 'USER/BULK_USERS_REQUEST',
    BULK_USERS_SUCCESS: 'USER/BULK_USERS_SUCCESS',
    BULK_USERS_FAILURE: 'USER/BULK_USERS_FAILURE',
    LIST_CAREGIVERS_REQUEST: 'USER/LIST_CAREGIVERS_REQUEST',
    LIST_CAREGIVERS_SUCCESS: 'USER/LIST_CAREGIVERS_SUCCESS',
    LIST_CAREGIVERS_FAILURE: 'USER/LIST_CAREGIVERS_FAILURE',
    CREATE_USER_SUCCESS: 'USER/CREATE_USER_SUCCESS',
    CREATE_USER_FAILURE: 'USER/CREATE_USER_FAILURE',
    GET_USER_SUCCESS: 'USER/GET_USER_SUCCESS',
    GET_USER_FAILURE: 'USER/GET_USER_FAILURE',
    UPDATE_USER_SUCCESS: 'USER/UPDATE_USER_SUCCESS',
    UPDATE_USER_FAILURE: 'USER/UPDATE_USER_FAILURE',
    DELETE_USER_SUCCESS: 'USER/DELETE_USER_SUCCESS',
    DELETE_USER_FAILURE: 'USER/DELETE_USER_FAILURE',
    LIST_PATIENT_PROGRESS_SUCCESS: 'USER/LIST_PATIENT_PROGRESS_SUCCESS',
    LIST_PATIENT_PROGRESS_FAILURE: 'USER/LIST_PATIENT_PROGRESS_FAILURE',
    GET_PATIENT_PROCESS_QUESTION_SUCCESS: 'USER/GET_PATIENT_PROCESS_QUESTION_SUCCESS',
    GET_PATIENT_PROCESS_QUESTION_FAILURE: 'USER/GET_PATIENT_PROCESS_QUESTION_FAILURE',
    GET_PATIENT_PROGRESS_SUCCESS: 'USER/GET_PATIENT_PROGRESS_SUCCESS',
    GET_PATIENT_PROGRESS_FAILURE: 'USER/GET_PATIENT_PROGRESS_FAILURE',
    UPDATE_PATIENT_PROGRESS_SECTION_SUCCESS: 'USER/UPDATE_PATIENT_PROGRESS_SECTION_SUCCESS',
    UPDATE_PATIENT_PROGRESS_SECTION_REQUEST: 'USER/UPDATE_PATIENT_PROGRESS_SECTION_REQUEST',
    UPDATE_PATIENT_PROGRESS_SECTION_FAILURE: 'USER/UPDATE_PATIENT_PROGRESS_SECTION_FAILURE',
    UPDATE_PATIENT_PROGRESS_CONTENT_SUCCESS: 'USER/UPDATE_PATIENT_PROGRESS_CONTENT_SUCCESS',
    UPDATE_PATIENT_PROGRESS_CONTENT_REQUEST: 'USER/UPDATE_PATIENT_PROGRESS_CONTENT_REQUEST',
    UPDATE_PATIENT_PROGRESS_CONTENT_FAILURE: 'USER/UPDATE_PATIENT_PROGRESS_CONTENT_FAILURE',
};
exports.INITIAL_STATE = {
    user: null,
    pending: false,
    error: null,
    supportMessageSent: false,
    users: [],
    filters: {
        ordering: null,
        page: 1,
        nextPage: false,
    },
    count: null,
    pendingBulk: false,
    pendingCaregivers: false,
    caregivers: [],
    pendingUserCreate: false,
    userDetail: null,
    patientProgressList: [],
    patientProgressFilters: {
        ordering: null,
        page: 1,
        nextPage: false,
    },
    patientProgressCount: null,
    patientProcessQuestions: null,
    patientProgress: [],
    patientProgressAccount: null,
    pendingPatientProgressSection: false,
    pendingPatientProgressContent: false,
};
const startUp = (state) => state;
const signUpFailure = (state, payload) => (Object.assign(Object.assign({}, state), { error: payload.details }));
const loginFailure = (state, payload) => (Object.assign(Object.assign({}, state), { error: payload.error }));
const updateUser = (state, payload) => (Object.assign(Object.assign({}, state), { user: payload.data || state.user }));
const storeUser = (state, payload) => (Object.assign(Object.assign({}, state), { user: payload.user }));
const logOut = (state) => (Object.assign(Object.assign({}, state), { user: new summithill_models_1.User() }));
const resetPasswordSuccess = (state) => (Object.assign({}, state));
const supportMessageSuccess = (state) => (Object.assign(Object.assign({}, state), { supportMessageSent: true }));
const resetSupportMessage = (state) => (Object.assign(Object.assign({}, state), { supportMessageSent: false }));
const storeRegisteredUser = (state, payload) => (Object.assign(Object.assign({}, state), { user: payload.data }));
const listUsersRequest = (state, payload) => !lodash_1.isEqual(payload, state.filters)
    ? Object.assign(Object.assign({}, state), { filters: payload, pending: true }) : Object.assign(Object.assign({}, state), { pending: true });
const listUsersSuccess = (state, payload) => (Object.assign(Object.assign({}, state), { pending: false, users: state.filters.nextPage
        ? [...state.users, ...payload.data.results.map(userData => new summithill_models_1.User(userData))]
        : payload.data.results.map(userData => new summithill_models_1.User(userData)), count: payload.data.count || state.count, filters: Object.assign(Object.assign({}, state.filters), { nextPage: false }) }));
const resetFilters = (state) => (Object.assign(Object.assign({}, state), { filters: { ordering: null, page: 1, nextPage: false }, patientProgressFilters: { ordering: null, page: 1, nextPage: false }, patientProgress: [], patientProgressAccount: null }));
const listUserFailure = (state) => (Object.assign(Object.assign({}, state), { pending: false }));
const bulkUsersRequest = (state) => (Object.assign(Object.assign({}, state), { pendingBulk: true }));
const bulkUsersSuccess = (state, payload) => {
    const { accounts } = payload === null || payload === void 0 ? void 0 : payload.data;
    return Object.assign(Object.assign({}, state), { pendingBulk: false, 
        // @ts-ignore
        users: lodash_1.default.mergeByKey(state.users, accounts.map((account) => new summithill_models_1.User(account)), 'id') });
};
const bulkUsersFailure = (state, payload) => (Object.assign(Object.assign({}, state), { error: payload === null || payload === void 0 ? void 0 : payload.error, pendingBulk: false }));
const listCaregiversRequest = (state) => (Object.assign(Object.assign({}, state), { pendingCaregivers: true }));
const listCaregiversSuccess = (state, payload) => {
    const { data } = payload;
    return Object.assign(Object.assign({}, state), { caregivers: data === null || data === void 0 ? void 0 : data.data.map((d) => new summithill_models_1.Caregiver(d)), pendingCaregivers: false });
};
const listCaregiversFailure = (state) => (Object.assign(Object.assign({}, state), { pendingCaregivers: false }));
const createUserSuccess = (state) => (Object.assign(Object.assign({}, state), { pendingUserCreate: false }));
const getUserSuccess = (state, payload) => {
    const { user } = payload === null || payload === void 0 ? void 0 : payload.data;
    return Object.assign(Object.assign({}, state), { userDetail: user });
};
const getUserFailure = (state, payload) => (Object.assign(Object.assign({}, state), { error: payload === null || payload === void 0 ? void 0 : payload.error }));
const updateUserFailure = (state, payload) => (Object.assign(Object.assign({}, state), { error: payload === null || payload === void 0 ? void 0 : payload.error }));
const updateUserSuccess = (state, payload) => {
    const { data } = payload;
    const { patient_responsibility, total_sessions } = data;
    return Object.assign(Object.assign({}, state), { userDetail: new summithill_models_1.User(Object.assign(Object.assign({}, state.userDetail), { patient_responsibility, total_sessions })) });
};
const createUserFailure = (state, payload) => (Object.assign(Object.assign({}, state), { error: payload === null || payload === void 0 ? void 0 : payload.error, pendingUserCreate: false }));
const deleteUserSuccess = (state) => (Object.assign(Object.assign({}, state), { userDetail: null }));
const deleteUserFailure = (state, payload) => (Object.assign(Object.assign({}, state), { error: payload === null || payload === void 0 ? void 0 : payload.error }));
const listPatientProgressSuccess = (state, payload) => {
    const { data } = payload;
    return Object.assign(Object.assign({}, state), { patientProgressList: state.patientProgressFilters.nextPage
            ? [
                ...state.patientProgressList,
                ...((data === null || data === void 0 ? void 0 : data.results) || []).map((d) => humps_1.camelizeKeys(d)),
            ]
            : ((data === null || data === void 0 ? void 0 : data.results) || []).map((d) => humps_1.camelizeKeys(d)), patientProgressCount: data.count || state.count, patientProgressFilters: Object.assign(Object.assign({}, state.patientProgressFilters), { nextPage: false }) });
};
const getPatientProcessQuestionSuccess = (state, payload) => (Object.assign(Object.assign({}, state), { patientProcessQuestions: payload.data }));
const getPatientProgressSuccess = (state, payload) => {
    const { data } = payload;
    return Object.assign(Object.assign({}, state), { patientProgress: data === null || data === void 0 ? void 0 : data.results.map((d) => new summithill_models_1.Section(d)), patientProgressAccount: new summithill_models_1.User(data === null || data === void 0 ? void 0 : data.account) });
};
const updatePatientProgressSectionSuccess = (state, payload) => {
    const { data } = payload;
    return Object.assign(Object.assign({}, state), { pendingPatientProgressSection: false, patientProgress: state.patientProgress.map((section) => {
            if ((section === null || section === void 0 ? void 0 : section.id) === (data === null || data === void 0 ? void 0 : data.id)) {
                return new summithill_models_1.Section(Object.assign({}, data));
            }
            return section;
        }) });
};
const updatePatientProgressContentSuccess = (state, payload) => {
    const { data } = payload;
    return Object.assign(Object.assign({}, state), { pendingPatientProgressContent: false, patientProgress: state.patientProgress.map((section) => new summithill_models_1.Section(Object.assign(Object.assign({}, section), { contents: section.contents.map((content) => {
                if ((content === null || content === void 0 ? void 0 : content.id) === (data === null || data === void 0 ? void 0 : data.id)) {
                    return data;
                }
                return content;
            }) }))) });
};
const updatePatientProgressSectionRequest = (state) => (Object.assign(Object.assign({}, state), { pendingPatientProgressSection: true }));
const updatePatientProgressSectionFailure = (state) => (Object.assign(Object.assign({}, state), { pendingPatientProgressSection: false }));
const updatePatientProgressContentRequest = (state) => (Object.assign(Object.assign({}, state), { pendingPatientProgressContent: true }));
const updatePatientProgressContentFailure = (state) => (Object.assign(Object.assign({}, state), { pendingPatientProgressContent: false }));
exports.reduxSet = {
    startUp: radio_dispatch_1.dispatchReducer(exports.ACTIONS.START_UP, startUp),
    loginSuccess: radio_dispatch_1.dispatchReducer(exports.ACTIONS.SET_USER_SESSION, shared_reducers_1.noOp),
    loginFailure: radio_dispatch_1.dispatchReducer(exports.ACTIONS.FAILURE_LOGIN, loginFailure),
    signUpSuccess: radio_dispatch_1.dispatchReducer(exports.ACTIONS.CREATE_USER, storeRegisteredUser),
    hydrateUserSuccess: radio_dispatch_1.dispatchReducer(exports.ACTIONS.HYDRATE_USER_SUCCESS, updateUser),
    hydrateUserFailure: radio_dispatch_1.dispatchReducer(exports.ACTIONS.HYDRATE_USER_FAILURE, logOut),
    logOut: radio_dispatch_1.dispatchReducer(exports.ACTIONS.LOGOUT, logOut),
    signUpFailure: radio_dispatch_1.dispatchReducer(exports.ACTIONS.FAILURE_CREATE_USER, signUpFailure),
    resetPasswordSuccess: radio_dispatch_1.dispatchReducer(exports.ACTIONS.RESET_PASSWORD_SUCCESS, resetPasswordSuccess),
    resetPasswordChangeSuccess: radio_dispatch_1.dispatchReducer(exports.ACTIONS.RESET_PASSWORD_CHANGE_SUCCESS, resetPasswordSuccess),
    createPasswordSuccess: radio_dispatch_1.dispatchReducer(exports.ACTIONS.CREATE_PASSWORD_SUCCESS, shared_reducers_1.noOp),
    dispatchStoreUser: radio_dispatch_1.dispatchReducer(exports.ACTIONS.STORE_USER, storeUser),
    sendSupportMessageSuccess: radio_dispatch_1.dispatchReducer(exports.ACTIONS.SEND_SUPPORT_MESSAGE_SUCCESS, supportMessageSuccess),
    resetSupportMessage: radio_dispatch_1.dispatchReducer(exports.ACTIONS.RESET_SUPPORT_MESSAGE, resetSupportMessage),
    listUsersRequest: radio_dispatch_1.dispatchReducer(exports.ACTIONS.LIST_USERS_REQUEST, listUsersRequest),
    listUsersSuccess: radio_dispatch_1.dispatchReducer(exports.ACTIONS.LIST_USERS_SUCCESS, listUsersSuccess),
    resetFilters: radio_dispatch_1.dispatchReducer(exports.ACTIONS.RESET_LIST_USERS_FILTER, resetFilters),
    listUserFailure: radio_dispatch_1.dispatchReducer(exports.ACTIONS.LIST_USERS_FAILURE, listUserFailure),
    bulkUsersRequest: radio_dispatch_1.dispatchReducer(exports.ACTIONS.BULK_USERS_REQUEST, bulkUsersRequest),
    bulkUsersSuccess: radio_dispatch_1.dispatchReducer(exports.ACTIONS.BULK_USERS_SUCCESS, bulkUsersSuccess),
    bulkUsersFailure: radio_dispatch_1.dispatchReducer(exports.ACTIONS.BULK_USERS_FAILURE, bulkUsersFailure),
    listCaregiversRequest: radio_dispatch_1.dispatchReducer(exports.ACTIONS.LIST_CAREGIVERS_REQUEST, listCaregiversRequest),
    listCaregiversSuccess: radio_dispatch_1.dispatchReducer(exports.ACTIONS.LIST_CAREGIVERS_SUCCESS, listCaregiversSuccess),
    listCaregiversFailure: radio_dispatch_1.dispatchReducer(exports.ACTIONS.LIST_CAREGIVERS_FAILURE, listCaregiversFailure),
    createUserSuccess: radio_dispatch_1.dispatchReducer(exports.ACTIONS.CREATE_USER_SUCCESS, createUserSuccess),
    createUserFailure: radio_dispatch_1.dispatchReducer(exports.ACTIONS.CREATE_USER_FAILURE, createUserFailure),
    getUserSuccess: radio_dispatch_1.dispatchReducer(exports.ACTIONS.GET_USER_SUCCESS, getUserSuccess),
    getUserFailure: radio_dispatch_1.dispatchReducer(exports.ACTIONS.GET_USER_FAILURE, getUserFailure),
    updateUserSuccess: radio_dispatch_1.dispatchReducer(exports.ACTIONS.UPDATE_USER_SUCCESS, updateUserSuccess),
    updateUserFailure: radio_dispatch_1.dispatchReducer(exports.ACTIONS.UPDATE_USER_FAILURE, updateUserFailure),
    deleteUserSuccess: radio_dispatch_1.dispatchReducer(exports.ACTIONS.DELETE_USER_SUCCESS, deleteUserSuccess),
    deleteUserFailure: radio_dispatch_1.dispatchReducer(exports.ACTIONS.DELETE_USER_FAILURE, deleteUserFailure),
    listPatientProgressSuccess: radio_dispatch_1.dispatchReducer(exports.ACTIONS.LIST_PATIENT_PROGRESS_SUCCESS, listPatientProgressSuccess),
    listPatientProgressFailure: radio_dispatch_1.dispatchReducer(exports.ACTIONS.LIST_PATIENT_PROGRESS_FAILURE, shared_reducers_1.noOp),
    getPatientProcessQuestionSuccess: radio_dispatch_1.dispatchReducer(exports.ACTIONS.GET_PATIENT_PROCESS_QUESTION_SUCCESS, getPatientProcessQuestionSuccess),
    getPatientProcessQuestionFailure: radio_dispatch_1.dispatchReducer(exports.ACTIONS.GET_PATIENT_PROCESS_QUESTION_FAILURE, shared_reducers_1.noOp),
    getPatientProgressSuccess: radio_dispatch_1.dispatchReducer(exports.ACTIONS.GET_PATIENT_PROGRESS_SUCCESS, getPatientProgressSuccess),
    getPatientProgressFailure: radio_dispatch_1.dispatchReducer(exports.ACTIONS.GET_PATIENT_PROGRESS_FAILURE, shared_reducers_1.noOp),
    updatePatientProgressSectionSuccess: radio_dispatch_1.dispatchReducer(exports.ACTIONS.UPDATE_PATIENT_PROGRESS_SECTION_SUCCESS, updatePatientProgressSectionSuccess),
    updatePatientProgressSectionFailure: radio_dispatch_1.dispatchReducer(exports.ACTIONS.UPDATE_PATIENT_PROGRESS_SECTION_FAILURE, updatePatientProgressSectionFailure),
    updatePatientProgressSectionRequest: radio_dispatch_1.dispatchReducer(exports.ACTIONS.UPDATE_PATIENT_PROGRESS_SECTION_REQUEST, updatePatientProgressSectionRequest),
    updatePatientProgressContentSuccess: radio_dispatch_1.dispatchReducer(exports.ACTIONS.UPDATE_PATIENT_PROGRESS_CONTENT_SUCCESS, updatePatientProgressContentSuccess),
    updatePatientProgressContentRequest: radio_dispatch_1.dispatchReducer(exports.ACTIONS.UPDATE_PATIENT_PROGRESS_CONTENT_REQUEST, updatePatientProgressContentRequest),
    updatePatientProgressContentFailure: radio_dispatch_1.dispatchReducer(exports.ACTIONS.UPDATE_PATIENT_PROGRESS_CONTENT_FAILURE, updatePatientProgressContentFailure),
};
const reducer = (state = Object.assign({}, exports.INITIAL_STATE), action) => radio_dispatch_1.runReducers(state, action, exports.reduxSet);
exports.reducer = reducer;
