"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.reducer = exports.reduxSet = exports.INITIAL_STATE = exports.ACTIONS = void 0;
const radio_dispatch_1 = require("@clearsummit/radio-dispatch");
const shared_reducers_1 = require("@/redux/shared-reducers");
exports.ACTIONS = {
    GET_THERAPY_NOTE_SUCCESS: 'THERAPY/GET_THERAPY_NOTE_SUCCESS',
    GET_THERAPY_NOTE_FAILURE: 'THERAPY/GET_THERAPY_NOTE_FAILURE',
    CREATE_THERAPY_NOTE_SUCCESS: 'THERAPY/CREATE_THERAPY_NOTE_SUCCESS',
    CREATE_THERAPY_NOTE_FAILURE: 'THERAPY/CREATE_THERAPY_NOTE_FAILURE',
    UPDATE_THERAPY_NOTE_SUCCESS: 'THERAPY/UPDATE_THERAPY_NOTE_SUCCESS',
    UPDATE_THERAPY_NOTE_FAILURE: 'THERAPY/UPDATE_THERAPY_NOTE_FAILURE',
    DELETE_THERAPY_NOTE_SUCCESS: 'THERAPY/DELETE_THERAPY_NOTE_SUCCESS',
    DELETE_THERAPY_NOTE_FAILURE: 'THERAPY/DELETE_THERAPY_NOTE_FAILURE',
};
exports.INITIAL_STATE = {
    therapyNote: null,
};
const getTherapyNoteSuccess = (state, payload) => {
    const { data } = payload;
    return Object.assign(Object.assign({}, state), { therapyNote: data === null || data === void 0 ? void 0 : data.therapynote });
};
const createTherapyNoteSuccess = (state, payload) => {
    const { data } = payload;
    return Object.assign(Object.assign({}, state), { therapyNote: data === null || data === void 0 ? void 0 : data.therapynote });
};
const updateTherapyNoteSuccess = (state, payload) => {
    const { data } = payload;
    return Object.assign(Object.assign({}, state), { therapyNote: data === null || data === void 0 ? void 0 : data.therapynote });
};
// @ts-ignore
const deleteTherapyNoteSuccess = (state) => (Object.assign(Object.assign({}, state), { therapyNote: null }));
exports.reduxSet = {
    getTherapyNoteSuccess: radio_dispatch_1.dispatchReducer(exports.ACTIONS.GET_THERAPY_NOTE_SUCCESS, getTherapyNoteSuccess),
    getTherapyNoteFailure: radio_dispatch_1.dispatchReducer(exports.ACTIONS.GET_THERAPY_NOTE_FAILURE, shared_reducers_1.noOp),
    createTherapyNoteSuccess: radio_dispatch_1.dispatchReducer(exports.ACTIONS.CREATE_THERAPY_NOTE_SUCCESS, createTherapyNoteSuccess),
    createTherapyNoteFailure: radio_dispatch_1.dispatchReducer(exports.ACTIONS.CREATE_THERAPY_NOTE_FAILURE, shared_reducers_1.noOp),
    updateTherapyNoteSuccess: radio_dispatch_1.dispatchReducer(exports.ACTIONS.UPDATE_THERAPY_NOTE_SUCCESS, updateTherapyNoteSuccess),
    updateTherapyNoteFailure: radio_dispatch_1.dispatchReducer(exports.ACTIONS.UPDATE_THERAPY_NOTE_FAILURE, shared_reducers_1.noOp),
    deleteTherapyNoteSuccess: radio_dispatch_1.dispatchReducer(exports.ACTIONS.DELETE_THERAPY_NOTE_SUCCESS, deleteTherapyNoteSuccess),
    deleteTherapyNoteFailure: radio_dispatch_1.dispatchReducer(exports.ACTIONS.DELETE_THERAPY_NOTE_SUCCESS, shared_reducers_1.noOp),
};
const reducer = (state = Object.assign({}, exports.INITIAL_STATE), action) => radio_dispatch_1.runReducers(state, action, exports.reduxSet);
exports.reducer = reducer;
