"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const carabiners_1 = require("@clearsummit/carabiners");
const React = __importStar(require("react"));
const react_helmet_1 = require("react-helmet");
const Meta = ({ titleTemplate, title, description, keywords, lang, openGraph, removeViewport }) => (React.createElement(react_helmet_1.Helmet, { titleTemplate: titleTemplate },
    React.createElement("html", { lang: lang }),
    title && React.createElement("title", null, title),
    description && React.createElement("meta", { name: "description", content: description }),
    keywords && keywords.length > 0 && React.createElement("meta", { name: "keywords", content: carabiners_1.joinStr({ char: ', ' }, ...keywords) }),
    openGraph.map(({ property, content }) => (React.createElement("meta", { property: property, content: content }))),
    removeViewport && React.createElement("meta", { name: "viewport", content: "" })));
Meta.defaultProps = {
    titleTemplate: '',
    title: '',
    description: '',
    keywords: [],
    openGraph: [],
    lang: 'en',
    removeViewport: false
};
exports.default = Meta;
