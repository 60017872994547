"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.therapyNoteSchema = exports.programEditSchema = exports.programCreateSchema = exports.sectionCreateSchema = exports.CONTENT_OPTIONS = exports.accountEditSchema = exports.AccountCreateSchema = exports.calendarUrlSchema = exports.meetingUrlSchema = exports.programSchema = exports.phoneSchema = exports.isActiveSchema = exports.groupIdSchema = exports.defaultLastNameSchema = exports.SupportSchema = exports.createQuestionSchema = exports.SignUpSchema = exports.defaultFirstNameSchema = exports.ResetPasswordChangeSchema = exports.PasswordCreationSchema = exports.ResetPasswordSchema = exports.LoginSchema = exports.requiredStringSchema = exports.defaultCheckboxSchema = exports.defaultConfirmPassword = exports.defaultPasswordSchema = exports.defaultEmailSchema = void 0;
/* eslint-disable no-useless-escape */
/* eslint-disable max-len */
const lodash_1 = require("lodash");
const yup = __importStar(require("yup"));
const constants_1 = __importStar(require("@/constants"));
const utils_1 = require("@/screens/manage-accounts/utils");
const types_1 = require("../screens/manage-accounts/types");
const FieldNames = __importStar(require("./field-names"));
const phoneRegExp = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
const urlRegExp = /^((https?|ftp):\/\/)?(www.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i;
exports.defaultEmailSchema = yup
    .string()
    .email()
    .required()
    .label('Email');
exports.defaultPasswordSchema = yup
    .string()
    .min(8, constants_1.default.signup.beAtLeast8)
    .max(50, constants_1.default.signup.notExceed50)
    .matches(constants_1.Regex.oneNumber, constants_1.default.signup.containAtLeast1Number)
    .matches(constants_1.Regex.oneSpecialChar, constants_1.default.signup.containAtLeast1Special)
    .test('empty-check', constants_1.default.signup.passwordMustBe, (password) => password.length !== 0)
    .label('Password');
exports.defaultConfirmPassword = yup
    .string()
    .oneOf([yup.ref('password'), null], constants_1.default.signup.passwordsDoNotMatch)
    .label('Password');
exports.defaultCheckboxSchema = yup
    .boolean()
    .oneOf([true])
    .required();
exports.requiredStringSchema = yup.string().required('');
exports.LoginSchema = yup.object().shape({
    [FieldNames.LoginFieldNames.username]: exports.defaultEmailSchema,
    [FieldNames.LoginFieldNames.password]: exports.defaultPasswordSchema,
});
exports.ResetPasswordSchema = yup.object().shape({
    [FieldNames.ResetPasswordFieldNames.username]: exports.defaultEmailSchema,
});
exports.PasswordCreationSchema = yup.object().shape({
    [FieldNames.PasswordCreationFieldNames.password]: exports.defaultPasswordSchema,
});
exports.ResetPasswordChangeSchema = yup.object().shape({
    [FieldNames.ResetPasswordChangeFieldNames.password]: exports.defaultPasswordSchema,
    [FieldNames.ResetPasswordChangeFieldNames.confirmPassword]: exports.defaultConfirmPassword,
});
exports.defaultFirstNameSchema = yup
    .string()
    .required()
    .label(constants_1.default.signup.firstName);
exports.SignUpSchema = yup.object().shape({
    [FieldNames.SignUpFieldNames.firstName]: exports.defaultFirstNameSchema,
    [FieldNames.SignUpFieldNames.email]: exports.defaultEmailSchema,
    [FieldNames.SignUpFieldNames.password]: exports.defaultPasswordSchema,
    [FieldNames.SignUpFieldNames.tos]: exports.defaultCheckboxSchema,
});
const createQuestionSchema = (idx) => yup.object().shape({
    [`${FieldNames.CreateQuestionsFieldNames.answer}-${idx}`]: exports.requiredStringSchema,
});
exports.createQuestionSchema = createQuestionSchema;
exports.SupportSchema = yup.object().shape({
    [FieldNames.SupportFieldNames.message]: exports.requiredStringSchema,
});
exports.defaultLastNameSchema = yup
    .string()
    .required()
    .label(constants_1.default.manageAccounts.create.lastName);
exports.groupIdSchema = yup
    .number()
    .required()
    .label(constants_1.default.manageAccounts.create.role);
exports.isActiveSchema = yup
    .boolean()
    .required()
    .label(constants_1.default.manageAccounts.create.userStatus);
exports.phoneSchema = yup
    .string()
    .matches(phoneRegExp, constants_1.default.general.phoneNumberNotValid)
    .label(constants_1.default.manageAccounts.create.phone);
exports.programSchema = yup
    .string()
    .when(FieldNames.AccountCreateFieldNames.groupId, {
    is: (groupId) => groupId === lodash_1.find(utils_1.groupOptions, { group: types_1.Groups.PATIENT }).value,
    then: yup.string().required(),
})
    .label(constants_1.default.manageAccounts.create.program);
exports.meetingUrlSchema = yup
    .string()
    .when(FieldNames.AccountCreateFieldNames.groupId, {
    is: (groupId) => groupId === lodash_1.find(utils_1.groupOptions, { group: types_1.Groups.CAREGIVER }).value,
    then: yup.string().matches(urlRegExp, constants_1.default.general.enterCorrectUrl),
})
    .label(constants_1.default.manageAccounts.create.meetingUrl);
exports.calendarUrlSchema = yup
    .string()
    .when(FieldNames.AccountCreateFieldNames.groupId, {
    is: (groupId) => groupId === lodash_1.find(utils_1.groupOptions, { group: types_1.Groups.CAREGIVER }).value,
    then: yup.string().matches(urlRegExp, constants_1.default.general.enterCorrectUrl),
})
    .label(constants_1.default.manageAccounts.create.meetingUrl);
exports.AccountCreateSchema = yup.object().shape({
    [FieldNames.AccountCreateFieldNames.email]: exports.defaultEmailSchema,
    [FieldNames.AccountCreateFieldNames.firstName]: exports.defaultFirstNameSchema,
    [FieldNames.AccountCreateFieldNames.lastName]: exports.defaultLastNameSchema,
    [FieldNames.AccountCreateFieldNames.groupId]: exports.groupIdSchema,
    [FieldNames.AccountCreateFieldNames.isActive]: exports.isActiveSchema,
    [FieldNames.AccountCreateFieldNames.programId]: exports.programSchema,
    [FieldNames.AccountCreateFieldNames.phone]: exports.phoneSchema,
    [FieldNames.AccountCreateFieldNames.caseManager]: yup
        .string()
        .label(constants_1.default.manageAccounts.create.caseManager),
    [FieldNames.AccountCreateFieldNames.therapist]: yup
        .string()
        .label(constants_1.default.manageAccounts.create.therapist),
    [FieldNames.AccountCreateFieldNames.sessionPrice]: yup
        .string()
        .label(constants_1.default.manageAccounts.create.sessionPrice),
    [FieldNames.AccountCreateFieldNames.totalPaidSessions]: yup
        .string()
        .label(constants_1.default.manageAccounts.create.sessionPrice),
    [FieldNames.AccountCreateFieldNames.caregiverType]: yup
        .string()
        .label(constants_1.default.manageAccounts.create.caregiverType),
    [FieldNames.AccountCreateFieldNames.calendarUrl]: exports.calendarUrlSchema,
    [FieldNames.AccountCreateFieldNames.meetingUrl]: exports.meetingUrlSchema,
});
exports.accountEditSchema = yup.object().shape({
    [FieldNames.AccountEditFieldNames.email]: exports.defaultEmailSchema,
    [FieldNames.AccountEditFieldNames.firstName]: exports.defaultFirstNameSchema,
    [FieldNames.AccountEditFieldNames.lastName]: exports.defaultLastNameSchema,
    [FieldNames.AccountEditFieldNames.groupId]: exports.groupIdSchema,
    [FieldNames.AccountEditFieldNames.isActive]: exports.isActiveSchema,
    [FieldNames.AccountEditFieldNames.programId]: exports.programSchema,
    [FieldNames.AccountEditFieldNames.phone]: exports.phoneSchema,
    [FieldNames.AccountEditFieldNames.caseManager]: yup
        .string()
        .label(constants_1.default.manageAccounts.create.caseManager),
    [FieldNames.AccountEditFieldNames.therapist]: yup
        .string()
        .label(constants_1.default.manageAccounts.create.therapist),
    [FieldNames.AccountEditFieldNames.sessionPrice]: yup
        .string()
        .label(constants_1.default.manageAccounts.create.sessionPrice),
    [FieldNames.AccountEditFieldNames.totalPaidSessions]: yup
        .string()
        .label(constants_1.default.manageAccounts.create.sessionPrice),
    [FieldNames.AccountEditFieldNames.caregiverType]: yup
        .string()
        .label(constants_1.default.manageAccounts.create.caregiverType),
    [FieldNames.AccountEditFieldNames.calendarUrl]: exports.calendarUrlSchema,
    [FieldNames.AccountEditFieldNames.meetingUrl]: exports.meetingUrlSchema,
});
exports.CONTENT_OPTIONS = [
    { value: 'VIDEO', label: constants_1.default.managePrograms.create.videoLabel },
    { value: 'PROCESS_QUESTION', label: constants_1.default.managePrograms.create.processQuestionLabel }
];
exports.sectionCreateSchema = yup.object().shape({
    [FieldNames.SectionCreateFieldNames.program]: yup.string().required(),
    [FieldNames.SectionCreateFieldNames.name]: yup.string().required(constants_1.default.general.required),
    [FieldNames.SectionCreateFieldNames.order]: yup.number().required(constants_1.default.general.required),
    [FieldNames.SectionCreateFieldNames.type]: yup.string().oneOf(['SESSION', 'CONTENT_SECTION']).required(constants_1.default.general.required),
    [FieldNames.SectionCreateFieldNames.sessionType]: yup
        .string()
        .oneOf(['THERAPY', 'CASE_MANAGEMENT', null])
        .when(FieldNames.SectionCreateFieldNames.type, {
        is: 'SESSION', then: yup.string().required(constants_1.default.general.required)
    })
        .nullable(),
    [FieldNames.SectionCreateFieldNames.contents]: yup.array().of(yup.object().shape({
        [FieldNames.ContentCreateFieldNames.content_type]: yup.string().oneOf(['VIDEO', 'PROCESS_QUESTION']).required(constants_1.default.general.required),
        [FieldNames.ContentCreateFieldNames.order]: yup.number().required(),
        [FieldNames.VideoCreateFieldNames.name]: yup.string().required(constants_1.default.general.required),
        [FieldNames.VideoCreateFieldNames.url]: yup
            .string()
            .when(FieldNames.ContentCreateFieldNames.content_type, {
            is: 'VIDEO', then: yup.string().required(constants_1.default.general.required)
        })
            .nullable(),
        [FieldNames.VideoCreateFieldNames.duration]: yup
            .string()
            .when(FieldNames.ContentCreateFieldNames.content_type, {
            is: 'VIDEO', then: yup.string().required(constants_1.default.general.required)
        })
            .nullable(),
        [FieldNames.ContentCreateFieldNames.questions]: yup.array().of(yup
            .object()
            .shape({
            [FieldNames.QuestionCreateFieldNames.wording]: yup.string().required(constants_1.default.general.required),
            [FieldNames.QuestionCreateFieldNames.placeholder]: yup.string(),
            [FieldNames.QuestionCreateFieldNames.order]: yup.number().required(constants_1.default.general.required)
        })
            .when(FieldNames.ContentCreateFieldNames.content_type, {
            is: 'PROCESS_QUESTION',
            then: yup.array().required(constants_1.default.general.required)
        })).nullable()
    })).nullable()
});
exports.programCreateSchema = yup.object().shape({
    [FieldNames.ProgramCreateFieldNames.name]: yup.string().required(constants_1.default.general.required),
});
exports.programEditSchema = yup.object().shape({
    [FieldNames.ProgramEditFieldNames.id]: yup.string().required(),
    [FieldNames.ProgramEditFieldNames.name]: yup.string().required(constants_1.default.general.required),
});
exports.therapyNoteSchema = yup.object().shape({
    [FieldNames.TherapyNoteFieldNames.datetime]: yup
        .string()
        .required()
        .label(constants_1.default.patientProgress.therapyNote.date)
        .nullable(),
    [FieldNames.TherapyNoteFieldNames.behavior]: yup
        .string()
        .label(constants_1.default.patientProgress.therapyNote.attitudeBehavior),
    [FieldNames.TherapyNoteFieldNames.mood]: yup
        .string()
        .label(constants_1.default.patientProgress.therapyNote.mood),
    [FieldNames.TherapyNoteFieldNames.speech]: yup
        .string()
        .label(constants_1.default.patientProgress.therapyNote.speech),
    [FieldNames.TherapyNoteFieldNames.affect]: yup
        .string()
        .label(constants_1.default.patientProgress.therapyNote.affect),
    [FieldNames.TherapyNoteFieldNames.thoughtProcess]: yup
        .string()
        .label(constants_1.default.patientProgress.therapyNote.thoughtProcess),
    [FieldNames.TherapyNoteFieldNames.cognition]: yup
        .string()
        .label(constants_1.default.patientProgress.therapyNote.cognition),
    [FieldNames.TherapyNoteFieldNames.insight]: yup
        .string()
        .label(constants_1.default.patientProgress.therapyNote.insight),
    [FieldNames.TherapyNoteFieldNames.keyStressorEvent]: yup
        .string()
        .label(constants_1.default.patientProgress.therapyNote.presentinIssueKeyStressOrEvent),
    [FieldNames.TherapyNoteFieldNames.recoveryEnvNotes]: yup
        .string()
        .label(constants_1.default.patientProgress.therapyNote.reviewDiscussionOfRecoveryEnvironment),
    [FieldNames.TherapyNoteFieldNames.sleepingEatingNotes]: yup
        .string()
        .label(constants_1.default.patientProgress.therapyNote.reportedCravingsUsingDreamsSleepQualityEatingAppetite),
    [FieldNames.TherapyNoteFieldNames.intervention]: yup
        .string()
        .label(constants_1.default.patientProgress.therapyNote.interventionProvidedToday),
    [FieldNames.TherapyNoteFieldNames.interventionResponse]: yup
        .string()
        .label(constants_1.default.patientProgress.therapyNote.clientResponseToIntervention),
    [FieldNames.TherapyNoteFieldNames.nextSessionPlan]: yup
        .string()
        .label(constants_1.default.patientProgress.therapyNote.planOrGoalForNextSession),
    [FieldNames.TherapyNoteFieldNames.careNecessity]: yup
        .string()
        .label(constants_1.default.patientProgress.therapyNote.necessityForContinuedLevelOfCare),
    [FieldNames.TherapyNoteFieldNames.additionalNotes]: yup
        .string()
        .label(constants_1.default.patientProgress.therapyNote.additionalTherapistNotesOrProgressTowardsGoals),
});
exports.default = {};
