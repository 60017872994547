"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sectionsNormalizer = void 0;
const summithill_models_1 = require("@clearsummit/summithill-models");
const normalizr_1 = require("normalizr");
const sectionContentSchema = (name) => new normalizr_1.schema.Entity(name, {}, { processStrategy: data => data });
const sectionContentArray = new normalizr_1.schema.Array({
    videos: sectionContentSchema('videos'),
    processquestions: sectionContentSchema('processquestions'),
}, input => input.type);
const sectionContentNormalizer = (sectionContents) => normalizr_1.normalize(sectionContents, sectionContentArray);
const sectionSchema = new normalizr_1.schema.Entity('sections', {}, {
    processStrategy: data => {
        const d = data;
        const { entities, result } = sectionContentNormalizer(d.contents);
        if (data instanceof summithill_models_1.Section) {
            d.contents = result;
            d.entities = entities;
        }
        return d;
    },
});
const sectionsNormalizer = (sections) => normalizr_1.normalize(sections, [sectionSchema]).entities;
exports.sectionsNormalizer = sectionsNormalizer;
